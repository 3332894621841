import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEqual from 'lodash/isEqual';

const INITIAL_BOARD_ID = 'board_initial';

interface IBoard {
  id: string;
  charts: { id: string }[];
  layout: {}[];
}

enum EInitialWidgetIDs {
  first = 'initial-preset-widget-1',
  second = 'initial-preset-widget-2',
  third = 'initial-preset-widget-3',
  fourth = 'initial-preset-widget-4',
  fifth = 'initial-preset-widget-5',
  sixth = 'initial-preset-widget-6',
}

const initialWidgetSet = new Set([EInitialWidgetIDs.first, EInitialWidgetIDs.second, EInitialWidgetIDs.third]);

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: INITIAL_BOARD_ID,
        charts: [
          {
            id: EInitialWidgetIDs.first,
          },
          {
            id: EInitialWidgetIDs.second,
          },
          {
            id: EInitialWidgetIDs.third,
          },
          {
            id: EInitialWidgetIDs.fourth,
          },
        ],
        layout: [
          {
            x: 0,
            y: 0,
          },
          {
            x: 1,
            y: 1,
          },
          {
            x: 2,
            y: 2,
          },
          {
            x: 3,
            y: 3,
          },
        ],
      },
    ] as IBoard[],
  },
};

const newAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: INITIAL_BOARD_ID,
        charts: [
          {
            id: EInitialWidgetIDs.first,
          },
          {
            id: EInitialWidgetIDs.second,
          },
          {
            id: EInitialWidgetIDs.third,
          },
        ],
        layout: [
          {
            x: 0,
            y: 0,
          },
          {
            x: 1,
            y: 1,
          },
          {
            x: 2,
            y: 2,
          },
        ],
      },
    ],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const boards = _get(prevAppearanceSettings, 'dashboard.boards', []);
    const parsedBoards = boards.map((board) => {
      const isInitialBoard = _get(board, 'id') === INITIAL_BOARD_ID;
      if (!isInitialBoard) {
        return board;
      }

      const charts = _get(board, 'charts', []);
      const layoutIds: number[] = [];
      const parsedCharts = charts.reduce((acc, chart, index) => {
        const isInitialWidget = initialWidgetSet.has(_get(chart, 'id'));
        if (isInitialWidget) {
          acc.push(chart);
          layoutIds.push(index);
        }
        return acc;
      }, []);

      const parsedLayout = _get(board, 'layout', []).filter((_, index) => layoutIds.includes(index));

      _set(board, 'charts', parsedCharts);
      _set(board, 'layout', parsedLayout);
      return board;
    });
    _set(prevAppearanceSettings, 'dashboard.boards', parsedBoards);
    return (prevAppearanceSettings as unknown) as TNewAppearanceSettingsShape;
  },
};
