interface IChartDefinitionStyle {
  size: number;
  zIndex: number;
  height: number;
}
interface IChartDefinition {
  dataSource: {
    apiUrl: string;
  };
  style: IChartDefinitionStyle;
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[] }[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[] }[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettingsShape,
      dashboard: {
        ...prevAppearanceSettingsShape.dashboard,
        boards: prevAppearanceSettingsShape.dashboard.boards.map((board) => {
          return {
            ...board,
            charts: [
              {
                ...board.charts[0],
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/ggr-total-period',
                },
                style: {
                  size: 4,
                  zIndex: 1301,
                  height: 500,
                },
              },
              {
                ...board.charts[1],
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/top-games-period',
                },
                style: {
                  size: 2,
                  zIndex: 1301,
                  height: 500,
                },
              },
              {
                ...board.charts[2],
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/ggr-total-period',
                },
                style: {
                  size: 2,
                  zIndex: 1301,
                  height: 500,
                },
              },
            ] as IChartDefinition[],
          };
        }),
      },
    };
  },
};
