export enum ETabIconVariant {
  DASHBOARD_ROUNDED = 'DASHBOARD_ROUNDED',
  TIMELINE_ROUNDED = 'TIMELINE_ROUNDED',
  RECEIPT_ROUNDED = 'RECEIPT_ROUNDED',
  EURO_SYMBOL_ROUNDED = 'EURO_SYMBOL_ROUNDED',
  SUPERVISED_USER_CIRCLE_ROUNDED = 'SUPERVISED_USER_CIRCLE_ROUNDED',
  CASINO_ROUNDED = 'CASINO_ROUNDED',
  DATE_RANGE_ROUNDED = 'DATE_RANGE_ROUNDED',
  DONUT_SMALL_ROUNDED = 'DONUT_SMALL_ROUNDED',
  GRADE_ROUNDED = 'GRADE_ROUNDED',
  FAVORITE_ROUNDED = 'FAVORITE_ROUNDED',
  SWAP_VERTICAL_ROUNDED = 'SWAP_VERTICAL_ROUNDED',
}

const prevAppearanceSettingsShape = {
  dashboard: {
    appearance: {},
    query: {},
    charts: [],
  },
};
const nextAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: 'DEFAULT_BOARD',
        name: 'Default Board',
        icon: ETabIconVariant.DASHBOARD_ROUNDED,
        appearance: {},
        query: {},
        charts: [],
      },
    ],
    activeBoardIndex: 0,
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNextAppearanceSettingsShape = typeof nextAppearanceSettingsShape;

export default {
  up: async (appearanceSettings: TPrevAppearanceSettingsShape): Promise<TNextAppearanceSettingsShape> => {
    const { ...restSettings } = appearanceSettings;

    return {
      ...restSettings,
      dashboard: {
        boards: [
          {
            ...nextAppearanceSettingsShape.dashboard.boards[0],
            ...restSettings.dashboard,
          },
        ],
        activeBoardIndex: nextAppearanceSettingsShape.dashboard.activeBoardIndex,
      },
    };
  },
};
