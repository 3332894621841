import _get from 'lodash/get';
import _merge from 'lodash/merge';

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: '1',
        name: 'test-board',
        query: { dateRange: { startDate: 'startDate', endDate: 'endDate' } },
        charts: [],
        layout: [],
        appearance: {},
      },
    ],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: '1',
        name: 'test-board',
        query: { dateRange: [{ startDate: 'startDate', endDate: 'endDate' }] },
        charts: [],
        layout: [],
        appearance: {
          toolbar: {
            resources: { level: 2 },
            games: { level: 2 },
            locations: { level: 2 },
            dateRange: { level: 2 },
            playersType: { level: 3 },
            currency: { level: 3 },
          },
        },
      },
    ],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return _merge(prevAppearanceSettings, {
      dashboard: {
        boards: _get(prevAppearanceSettings, 'dashboard.boards', []).map((board) => {
          return {
            ...board,
            appearance: {
              ...board?.appearance,
              toolbar: {
                resources: { level: 2 },
                games: { level: 2 },
                locations: { level: 2 },
                dateRange: { level: 2 },
                playersType: { level: 3 },
                currency: { level: 3 },
              },
            },
          };
        }),
      },
    });
  },
};
