interface IChartDefinition {
  name: string;
  template: string;
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[] }[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[] }[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettingsShape,
      dashboard: {
        ...prevAppearanceSettingsShape.dashboard,
        boards: prevAppearanceSettingsShape.dashboard.boards.map((board) => {
          return {
            ...board,
            charts: [
              {
                ...board.charts[0],
                name: 'Time Series',
                template: 'LINE_TIME_SERIES_BY_METRIC',
              },
              {
                ...board.charts[1],
                name: 'Time Series',
                template: 'LINE_TIME_SERIES_BY_METRIC_CUMULATIVE',
              },
              {
                ...board.charts[2],
                name: 'Top',
                template: 'PIE_TOP_GAMES_BY_METRIC',
              },
            ] as IChartDefinition[],
          };
        }),
      },
    };
  },
};
