interface IChartDefinition {
  chartType: 'pie';
  compareDatePeriodsAvailable: boolean;
  compareDatePeriodsEnabled: boolean;
  dateRanges: [object] | [object, object];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[] }[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[] }[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettingsShape,
      dashboard: {
        ...prevAppearanceSettingsShape.dashboard,
        boards: prevAppearanceSettingsShape.dashboard.boards.map((board) => {
          return {
            ...board,
            charts: board.charts.map((chartDefinition) => {
              const patch: Partial<IChartDefinition> = {};
              if (chartDefinition.chartType === 'pie') {
                patch.compareDatePeriodsAvailable = false;
                patch.compareDatePeriodsEnabled = false;
                patch.dateRanges = [chartDefinition.dateRanges[0]];
              }
              return {
                ...chartDefinition,
                ...patch,
              };
            }),
          };
        }),
      },
    };
  },
};
