const prevAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  documentationArea: {
    test: 'data',
  },
};
const newAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  documentationArea: {},
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettings,
      documentationArea: {},
    };
  },
};
