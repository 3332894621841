import createStore from 'x-common/store/configureStore';

import reducers, { initialState } from './reducers';
// eslint-disable-next-line import/no-cycle
import sagas from './sagas';

const { store, apiClient } = createStore({
  reducers,
  sagas,
  initialState,
});

export { apiClient };
export default store;
