import _isNil from 'lodash/isNil';

import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';

import migrationWidgets from 'migrations/utils/migrationWidgets';

interface ILazyChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IChartDefinitionFormDataMigration extends IChartDefinitionFormData {
  locationsEnabled: true;
  availableEntityUnits: string[];
  selectedEntityUnit: string;
}
interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export const affectedTemplates = ['PIE_PROJECTS_BY_BETS_THIS_MONTH'];

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormDataMigration = IChartDefinitionFormDataMigration,
    ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(prevAppearanceSettings, (formattedObject) => {
      const shouldUpdate = affectedTemplates.some((templateName) => {
        return formattedObject.get('template') === templateName;
      });
      if (!shouldUpdate) {
        return formattedObject;
      }

      return formattedObject.set('availableEntityUnits', ['partners']).set('selectedEntityUnit', 'partners');
    });
  },
};
