import _get from 'lodash/get';
import _set from 'lodash/set';

const prevAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  report: {
    geo: {
      appearance: {
        test: 'data',
      },
      results: {
        test: 'data',
        query: {
          test: 'data',
          resourcesNew: [],
          resources: [
            {
              title: 'Test PRJ Resource',
              value: 1,
              tag: 'PRJ',
              color: 'primary',
              children: [
                {
                  title: 'Test WL Resource',
                  value: 1,
                  tag: 'WL',
                  color: 'primary',
                  children: [],
                  selectAll: false,
                },
                {
                  title: 'Test WL Resource 2',
                  value: 2,
                  tag: 'WL',
                  color: 'primary',
                  children: [],
                  selectAll: false,
                },
              ],
            },
            {
              title: 'Test WL Resource',
              value: 1,
              tag: 'WL',
              color: 'primary',
              children: [],
              selectAll: false,
            },
          ],
        },
        chart: {
          test: 'data',
          resourcesNew: [],
          resources: [
            {
              title: 'Test PRJ Resource',
              value: 1,
              tag: 'PRJ',
              color: 'primary',
              children: [
                {
                  title: 'Test WL Resource',
                  value: 1,
                  tag: 'WL',
                  color: 'primary',
                  children: [],
                  selectAll: false,
                },
                {
                  title: 'Test WL Resource 2',
                  value: 2,
                  tag: 'WL',
                  color: 'primary',
                  children: [],
                  selectAll: false,
                },
              ],
            },
            {
              title: 'Test WL Resource',
              value: 1,
              tag: 'WL',
              color: 'primary',
              children: [],
              selectAll: false,
            },
          ],
        },
      },
    },
    general: {
      test: 'data',
      qwe: 1,
    },
  },
};

const newAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  report: {
    geo: {
      appearance: {
        test: 'data',
      },
      results: {
        test: 'data',
        query: {
          test: 'data',
          resources: [],
          resourcesNew: [
            {
              value: 1,
              tag: 'PRJ',
            },
            {
              value: 1,
              tag: 'WL',
            },
          ],
        },
        chart: {
          chartType: 'map',
          test: 'data',
          resources: [],
          resourcesNew: [
            {
              value: 1,
              tag: 'PRJ',
            },
            {
              value: 1,
              tag: 'WL',
            },
          ],
        },
      },
    },
    general: {
      test: 'data',
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    _set(prevAppearanceSettings, 'report.geo.results.query.resourcesNew', []);
    _set(prevAppearanceSettings, 'report.geo.results.query.resources', []);
    _set(prevAppearanceSettings, 'report.geo.results.chart.resources', []);
    _set(prevAppearanceSettings, 'report.geo.results.chart.resourcesNew', []);
    return (prevAppearanceSettings as unknown) as TNewAppearanceSettingsShape;
  },
};
