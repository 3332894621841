import _get from 'lodash/get';
import _set from 'lodash/set';

interface IBoard {
  id: string;
  query: {
    resources: Array<{ title: string; value: string | number; tag: string }>;
  };
}

const prevAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  dashboard: {
    boards: [
      {
        query: {
          resources: [
            {
              title: 'Test PRJ Resource',
              value: 1,
              tag: 'PRJ',
              children: [
                {
                  title: 'Test WL Resource',
                  value: 1,
                  tag: 'WL',
                },
                {
                  title: 'Test WL Resource 2',
                  value: 2,
                  tag: 'WL',
                },
              ],
            },
            {
              title: 'Test WL Resource',
              value: 1,
              tag: 'WL',
            },
          ],
        },
      },
    ] as IBoard[],
  },
  report: {
    general: {
      appearance: {
        test: 'data',
      },
      results: {
        test: 'data',
        query: {
          test: 'data',
          resourcesNew: [],
          resources: [
            {
              title: 'Test PRJ Resource',
              value: 1,
              tag: 'PRJ',
              color: 'primary',
              children: [
                {
                  title: 'Test WL Resource',
                  value: 1,
                  tag: 'WL',
                  color: 'primary',
                  children: [],
                  selectAll: false,
                },
                {
                  title: 'Test WL Resource 2',
                  value: 2,
                  tag: 'WL',
                  color: 'primary',
                  children: [],
                  selectAll: false,
                },
              ],
            },
            {
              title: 'Test WL Resource',
              value: 1,
              tag: 'WL',
              color: 'primary',
              children: [],
              selectAll: false,
            },
          ],
        },
      },
    },
    geo: {
      test: 'data',
      qwe: 1,
    },
  },
};
const newAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  dashboard: {
    boards: [
      {
        query: {
          resources: [
            {
              value: 1,
              tag: 'PRJ',
            },
            {
              value: 1,
              tag: 'WL',
            },
          ],
        },
      },
    ],
  },
  report: {
    general: {
      appearance: {
        test: 'data',
      },
      results: {
        test: 'data',
        query: {
          test: 'data',
          resources: [],
          resourcesNew: [
            {
              value: 1,
              tag: 'PRJ',
            },
            {
              value: 1,
              tag: 'WL',
            },
          ],
        },
      },
    },
    geo: {
      test: 'data',
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const generalReportResources = _get(prevAppearanceSettings, 'report.general.results.query.resources', []);
    const parsedReportCheckedNodes = generalReportResources
      .map((resource) => {
        if (['PRJ', 'COM'].includes(resource.tag) && !resource.children?.length) {
          return null;
        }

        const parent = generalReportResources.find((parentResource) => {
          return parentResource.children?.some(
            (child) => child.value === resource.value && child.tag === resource.tag && child.title === resource.title,
          );
        });

        const result: { value: string | number; tag: string; parentUniqKey?: string } = {
          value: resource.value,
          tag: resource.tag,
        };
        if (parent) {
          result.parentUniqKey = `${parent.tag}-${parent.value}`;
        }

        return result;
      })
      .filter((e) => e);

    _set(prevAppearanceSettings, 'report.general.results.query.resourcesNew', parsedReportCheckedNodes);
    _set(prevAppearanceSettings, 'report.general.results.query.resources', []);

    const boards = _get(prevAppearanceSettings, 'dashboard.boards', []);
    const parsedBoards = boards.map((board) => {
      const boardResources = _get(board, 'query.resources');
      const parsedResources = boardResources
        .map((resource) => {
          if (['PRJ', 'COM'].includes(resource.tag) && !resource.children?.length) {
            return null;
          }

          const parent = boardResources.find((parentResource) => {
            return parentResource.children?.some(
              (child) => child.value === resource.value && child.tag === resource.tag && child.title === resource.title,
            );
          });

          const result: { value: string | number; tag: string; parentUniqKey?: string } = {
            value: resource.value,
            tag: resource.tag,
          };
          if (parent) {
            result.parentUniqKey = `${parent.tag}-${parent.value}`;
          }

          return result;
        })
        .filter((e) => e);

      _set(board, 'query.resources', parsedResources);

      return board;
    });
    _set(prevAppearanceSettings, 'dashboard.boards', parsedBoards);

    return (prevAppearanceSettings as unknown) as TNewAppearanceSettingsShape;
  },
};
