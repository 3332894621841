/* eslint-disable camelcase */
import { RecordOf } from 'immutable';

import { StorageAdapterAWSS3 } from 'x-common/services/appearance/storageAdapters/awsS3/StorageAdapterAWSS3';
import AppearanceService from 'x-common/services/appearance/AppearanceService';
import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { putObject } from 'x-common/services/appearance/components/AppearanceStore/hooks/usePutObject';

// eslint-disable-next-line max-len
import { deprecated_widgetId } from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/widgetId';
import getJoiSchema from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/getJoiSchema';
// eslint-disable-next-line max-len
import getObjectFormatter from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/getObjectFormatter';

import { TChartType } from 'x-common/components/Chart';

import { DEFAULT_TIMEZONE_NAME, getTimezoneOption } from 'x-common/utils/timezones';
import logger from 'x-common/utils/logger';

import apiClient from 'apiClient';

interface IChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IBoard {
  id: number;
  charts: IChartDefinition[];
  layout: any[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

const objectNormalizer = <
  ChartDefinitionFormData extends IChartDefinitionFormData = IChartDefinitionFormData,
  ChartDefinition extends IChartDefinition = IChartDefinition
>(
  chartDefinition: RecordOf<ChartDefinitionFormData>,
) => {
  return chartDefinition.toJS() as ChartDefinition;
};

const populateChartDefinition = <ChartDefinition extends IChartDefinition = IChartDefinition>(
  chartDefinition: ChartDefinition,
): ChartDefinition => {
  return {
    ...chartDefinition,
    timezone: getTimezoneOption(DEFAULT_TIMEZONE_NAME),
  };
};

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormData = IChartDefinitionFormData,
    ChartDefinition extends IChartDefinition = IChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    const storageAdapter = new StorageAdapterAWSS3({ apiClient });
    const appearanceService = new AppearanceService({ storageAdapter });
    const pairsArray = prevAppearanceSettings.dashboard.boards.reduce((acc, board) => {
      const boardIdWidgetPairArray = board.charts.map((widget) => [board.id, widget] as [IBoard['id'], ChartDefinition]);
      acc.push(...boardIdWidgetPairArray);
      return acc;
    }, [] as [IBoard['id'], ChartDefinition][]);
    const migratedPairsArray: [IBoard['id'], RecordOf<ChartDefinitionFormData>][] = await pairsArray.reduce(
      (acc, pairArray) => {
        const [boardId, chartDefinition] = pairArray;
        const objectId = deprecated_widgetId(boardId, chartDefinition.id);
        const objectFormatter = getObjectFormatter(chartDefinition.chartType) as (
          object: ChartDefinition,
        ) => RecordOf<ChartDefinitionFormData>;
        let formattedObject: RecordOf<ChartDefinitionFormData>;
        try {
          formattedObject = objectFormatter(populateChartDefinition(chartDefinition));
        } catch (ex: unknown) {
          const error = ex as Error;
          logger.error('ChartDefinitionInvalidatedDuringMigration', error, { chartDefinition });

          return acc;
        }
        return acc.then((migratedPairsArray) => {
          return putObject<ChartDefinition, RecordOf<ChartDefinitionFormData>>(formattedObject, {
            appearanceService,
            objectId,
            joiSchema: getJoiSchema(chartDefinition.chartType),
            objectNormalizer,
            objectFormatter,
          }).then((chartDefinition) => {
            const pairArray: [IBoard['id'], RecordOf<ChartDefinitionFormData>] = [boardId, chartDefinition];
            migratedPairsArray.push(pairArray);
            return migratedPairsArray;
          });
        });
      },
      Promise.resolve([] as [IBoard['id'], RecordOf<ChartDefinitionFormData>][]),
    );
    const boards: IBoard[] = prevAppearanceSettings.dashboard.boards.map((board) => {
      const chartDefinitions = migratedPairsArray.reduce((acc, pairArray) => {
        const [boardId, chartDefinition] = pairArray;
        if (boardId === board.id) {
          const lazyChartDefinition: ChartDefinition = {
            id: chartDefinition.get('id'),
            chartType: chartDefinition.get('chartType'),
          } as ChartDefinition;
          acc.push(lazyChartDefinition);
        }
        return acc;
      }, [] as ChartDefinition[]);
      return {
        ...board,
        charts: chartDefinitions,
        layout: board.layout.slice(0, chartDefinitions.length),
      };
    });
    return {
      ...prevAppearanceSettings,
      dashboard: {
        ...prevAppearanceSettings.dashboard,
        boards,
      },
    };
  },
};
