import _merge from 'lodash/merge';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';

const prevAppearanceSettingsShape = {
  report: {
    general: {
      appearance: {},
      results: {
        query: {
          userId: { title: 'All owners', value: null, color: 'primary' },
        },
      },
    },
    geo: {
      appearance: {},
      results: {
        query: {
          userId: { title: 'All owners', value: null, color: 'primary' },
        },
        chart: {},
      },
    },
  },
};
const newAppearanceSettingsShape = {
  report: {
    general: {
      appearance: {},
      results: {
        query: {},
      },
    },
    geo: {
      appearance: {},
      results: {
        query: {},
        chart: {},
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const result = _merge(prevAppearanceSettings, {});

    if (!_isUndefined(_get(result, 'report.general.results.query.userId'))) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete result.report.general.results.query.userId;
    }

    if (!_isUndefined(_get(result, 'report.geo.results.query.userId'))) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete result.report.geo.results.query.userId;
    }

    return result;
  },
};
