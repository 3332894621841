import { fromJS } from 'immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';

import combineReducers from 'x-common/store/combineReducers';

import authReducer, { defaultAuthRootStateRecord } from 'x-common/bundles/AuthBundle/models/reducers';
import gamesAreaReducer, { defaultGamesAreaRootStateRecord } from 'x-common/bundles/GamesAreaBundle/models/reducers';
import {
  sessionsReportReducer,
  defaultSessionsReportRootStateRecord,
} from 'x-common/bundles/SessionsReportBundle/models/reducers';
import {
  playersReportReducer,
  defaultPlayersReportRootStateRecord,
} from 'x-common/bundles/PlayersReportBundle/models/reducers';
import {
  promotionsReportReducer,
  defaultPromotionsReportRootStateRecord,
} from 'x-common/bundles/PromotionsReportBundle/models/reducers';
import {
  metaPlayerReportReducer,
  defaultMetaPlayerReportRootStateRecord,
} from 'x-common/bundles/MetaPlayerReportBundle/models/reducers';
import {
  metaSessionReportReducer,
  defaultMetaSessionReportRootStateRecord,
} from 'x-common/bundles/MetaSessionReportBundle/models/reducers';
import companyReducer, { defaultCompanyRootStateRecord } from 'x-common/bundles/CompanyBundle/models/reducers';
import contactReducer, { defaultContactRootStateRecord } from 'x-common/bundles/UserBundle/models/reducers/contactReducer';
import billingEntityReducer, {
  defaultBillingEntityRootStateRecord,
} from 'x-common/bundles/CompanyBundle/models/reducers/billingEntityReducer';
import deductionReducer, { defaultDeductionRootStateRecord } from 'x-common/bundles/DeductionBundle/models/reducers';
import reportReducer, { defaultReportRootStateRecord } from 'x-common/bundles/GeneralReportBundle/models/reducers';
import { freeSpinsReducer, defaultFreeSpinsRootStateRecord } from 'x-common/bundles/FreeSpinBundle/models/reducers';
import dashboardReducer, { defaultDashboardRootStateRecord } from 'x-common/bundles/DashboardBundle/models/reducers';
import userReducer, { defaultUserRootStateRecord } from 'x-common/bundles/UserBundle/models/reducers/userReducer';
import userGroupReducer, {
  defaultUserGroupRootStateRecord,
} from 'x-common/bundles/UserBundle/models/reducers/userGroupReducer';
import { dailyDropReducer, defaultDailyDropRootStateRecord } from 'x-common/bundles/DailyDropBundle/models/reducers';
import {
  tournamentRaceReducer,
  defaultTournamentRaceRootStateRecord,
} from 'x-common/bundles/TournamentRaceBundle/models/reducers';
import { shortRaceReducer, defaultShortRaceRootStateRecord } from 'x-common/bundles/ShortRaceBundle/models/reducers';
import documentationAreaReducer, {
  defaultDocumentationAreaRootStateRecord,
} from 'x-common/bundles/DocumentationAreaBundle/models/reducers';
import currencyReportReducer, {
  defaultCurrencyReportRootStateRecord,
} from 'x-common/bundles/CurrencyReport/models/reducers';

import toasterReducer, { defaultToastRootStateRecord } from 'x-common/services/toasters/reducers';
import { generalDataReducer, defaultGeneralDataRootStateRecord } from 'x-common/services/generalData/reducers';
import { notificationReducer, defaultNotificationRootStateRecord } from 'x-common/services/notification/reducers';
import { snapshotsReducer, defaultSnapshotRootStateRecord } from 'x-common/services/snapshots/models/reducers';

import history from 'x-common/utils/browserHistory';

/* START SECTION | Hide Features */
/* END SECTION | Hide Features */

export const initialState = fromJS({
  auth: defaultAuthRootStateRecord,
  company: defaultCompanyRootStateRecord,
  person: defaultContactRootStateRecord,
  billingEntity: defaultBillingEntityRootStateRecord,
  deduction: defaultDeductionRootStateRecord,
  report: defaultReportRootStateRecord,
  currencyReport: defaultCurrencyReportRootStateRecord,
  dailyDrop: defaultDailyDropRootStateRecord,
  tournamentRace: defaultTournamentRaceRootStateRecord,
  shortRace: defaultShortRaceRootStateRecord,
  freeSpins: defaultFreeSpinsRootStateRecord,
  sessionsReport: defaultSessionsReportRootStateRecord,
  playersReport: defaultPlayersReportRootStateRecord,
  promotionsReport: defaultPromotionsReportRootStateRecord,
  metaPlayerReport: defaultMetaPlayerReportRootStateRecord,
  metaSessionReport: defaultMetaSessionReportRootStateRecord,
  generalData: defaultGeneralDataRootStateRecord,
  dashboard: defaultDashboardRootStateRecord,
  user: defaultUserRootStateRecord,
  userGroup: defaultUserGroupRootStateRecord,
  gamesArea: defaultGamesAreaRootStateRecord,
  documentationArea: defaultDocumentationAreaRootStateRecord,
  notification: defaultNotificationRootStateRecord,
  toaster: defaultToastRootStateRecord,
  snapshots: defaultSnapshotRootStateRecord,
});

export default combineReducers({
  auth: authReducer,
  company: companyReducer,
  person: contactReducer,
  billingEntity: billingEntityReducer,
  deduction: deductionReducer,
  report: reportReducer,
  currencyReport: currencyReportReducer,
  dailyDrop: dailyDropReducer,
  tournamentRace: tournamentRaceReducer,
  shortRace: shortRaceReducer,
  freeSpins: freeSpinsReducer,
  sessionsReport: sessionsReportReducer,
  playersReport: playersReportReducer,
  promotionsReport: promotionsReportReducer,
  metaPlayerReport: metaPlayerReportReducer,
  metaSessionReport: metaSessionReportReducer,
  generalData: generalDataReducer,
  dashboard: dashboardReducer(),
  user: userReducer,
  userGroup: userGroupReducer,
  gamesArea: gamesAreaReducer,
  documentationArea: documentationAreaReducer,
  notification: notificationReducer,
  toaster: toasterReducer,
  snapshots: snapshotsReducer,
  form: formReducer,
  router: connectRouter(history),
});
