import { fromJS } from 'immutable';

import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';
import migrationWidgets from 'migrations/utils/migrationWidgets';

interface ILazyChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IChartDefinitionFormDataMigration extends IChartDefinitionFormData {
  locationsEnabled: true;
}
interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormDataMigration = IChartDefinitionFormDataMigration,
    ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(
      prevAppearanceSettings,
      (formattedObject) => {
        // number 3 is symbolizes that the widget is already migrated
        if (
          formattedObject.get('resources') &&
          formattedObject.get('resources').size &&
          Object.keys(formattedObject.get('resources').toJS()[0]).length > 3
        ) {
          return formattedObject.set(
            'resources',
            formattedObject
              .get('resources')
              .map((resource) => {
                if (['PRJ', 'COM'].includes(resource.get('tag')) && !(resource as any).get('children')?.size) {
                  return null;
                }

                const parent = (formattedObject.get('resources') as any).find((parentResource) => {
                  return parentResource.get('children')?.some((childResource) => {
                    return (
                      childResource.get('value') === resource.get('value') &&
                      childResource.get('tag') === resource.get('tag') &&
                      (childResource.get('title') === (resource as any).get('title') ||
                        childResource.get('title') === (resource as any).get('alias'))
                    );
                  });
                });

                const resultShape: { value: string | number; tag: string; parentUniqKey?: string } = {
                  value: resource.get('value'),
                  tag: resource.get('tag'),
                };
                if (parent) {
                  resultShape.parentUniqKey = `${parent.get('tag')}-${parent.get('value')}`;
                }

                return fromJS(resultShape);
              })
              .filter((e) => e),
          );
        }

        return formattedObject;
      },
      { ignoreJoiValidation: true },
    );
  },
};
