/* eslint-disable camelcase */
import { List } from 'immutable';

import DateRangeBuilder, {
  DateRangeGenericSchemaRecord,
  DateRangeRecord,
  TDateRangeRecord,
} from 'x-common/services/DateRangeBuilder';
import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';
import migrationWidgets from 'migrations/utils/migrationWidgets';

interface IChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IBoard {
  id: string;
  charts: IChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormData = IChartDefinitionFormData,
    ChartDefinition extends IChartDefinition = IChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(prevAppearanceSettings, (formattedObject) => {
      if (!formattedObject.get('dateRanges').size || formattedObject.get('dateRanges').size === 1) {
        return formattedObject;
      }
      const prevActiveDateRangeRecord = formattedObject.get('dateRanges').get(0)!;
      const prevCompareDateRangeRecord = formattedObject.get('dateRanges').get(1)!;
      const activeDateRangeBuilder = new DateRangeBuilder(prevActiveDateRangeRecord.get('schema')?.toObject(), {
        excludeFutureDays: true,
        fallbackRange: {
          startDate: prevActiveDateRangeRecord.get('startDate'),
          endDate: prevActiveDateRangeRecord.get('endDate'),
        },
      });
      const activeDateRange = activeDateRangeBuilder.getPrebuiltDateRange();
      const activeDateRangeRecord = DateRangeRecord({
        startDate: activeDateRange?.startDate.toDate() || prevActiveDateRangeRecord.get('startDate'),
        endDate: activeDateRange?.endDate.toDate() || prevActiveDateRangeRecord.get('endDate'),
        schema: activeDateRange?.schema
          ? DateRangeGenericSchemaRecord(activeDateRange.schema)
          : prevActiveDateRangeRecord.get('schema'),
      });
      const compareDateRangeBuilder = new DateRangeBuilder(prevActiveDateRangeRecord.get('schema')?.toObject(), {
        excludeFutureDays: true,
        comparison: true,
        fallbackRange: {
          startDate: prevActiveDateRangeRecord.get('startDate'),
          endDate: prevActiveDateRangeRecord.get('endDate'),
        },
      });
      const compareDateRange = compareDateRangeBuilder.getPrebuiltDateRange();
      const compareDateRangeRecord = DateRangeRecord({
        startDate: compareDateRange?.startDate.toDate() || prevCompareDateRangeRecord.get('startDate'),
        endDate: compareDateRange?.endDate.toDate() || prevCompareDateRangeRecord.get('endDate'),
        schema: compareDateRange?.schema
          ? DateRangeGenericSchemaRecord(compareDateRange.schema)
          : prevCompareDateRangeRecord.get('schema'),
      });
      const dateRanges = List<TDateRangeRecord>([activeDateRangeRecord, compareDateRangeRecord]);
      return formattedObject.set('dateRanges', dateRanges);
    });
  },
};
