import _get from 'lodash/get';
import _set from 'lodash/set';

const prevAppearanceSettingsShape = {
  auth: {
    test: 'data',
  },
  dashboard: {
    boards: [
      {
        query: {
          games: [
            {
              title: 'Test game 1',
              value: 1,
              tag: 'GM',
              games: [2155, 2156],
            },
            {
              title: 'Test game 2',
              value: 2,
              tag: 'GM',
              games: [2167, 2168],
            },
          ],
        },
      },
    ],
  },
  report: {
    general: {
      results: {
        query: {
          resources: [{ test: 'data' }],
          resourcesNew: [{ test: 'data' }],
          games: [{ test: 'data' }],
          locations: [{ test: 'data' }],
          locationsNew: [{ test: 'data' }],
        },
      },
    },
    geo: {
      results: {
        query: {
          resources: [{ test: 'data' }],
          resourcesNew: [{ test: 'data' }],
          games: [{ test: 'data' }],
          locations: [{ test: 'data' }],
          locationsNew: [{ test: 'data' }],
        },
        chart: {
          resources: [],
          resourcesNew: [{ test: 'data' }],
          games: [{ test: 'data' }],
          locations: [{ test: 'data' }],
          locationsNew: [{ test: 'data' }],
        },
      },
    },
  },
  documentationArea: {
    query: {
      games: [{ test: 'data' }],
      language: [{ test: 'data' }],
      documentType: [{ test: 'data' }],
      countries: [{ test: 'data' }],
    },
  },
};
const newAppearanceSettingsShape = {
  auth: {
    test: 'data',
  },
  dashboard: {
    boards: [
      {
        query: {
          games: [
            {
              value: 2155,
              tag: 'GM',
            },
            {
              value: 2156,
              tag: 'GM',
            },
            {
              value: 2167,
              tag: 'GM',
            },
            {
              value: 2168,
              tag: 'GM',
            },
          ],
        },
      },
    ],
  },
  report: {
    general: {
      results: {
        query: {
          resources: [],
          games: [],
          locations: [],
        },
      },
    },
    geo: {
      results: {
        query: {
          resources: [],
          games: [],
          locations: [],
        },
        chart: {
          resources: [],
          games: [],
          locations: [],
        },
      },
    },
  },
  documentationArea: {
    query: {
      games: [],
      language: [],
      documentType: [],
      countries: [],
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    _set(prevAppearanceSettings, 'report.general.results.query.resources', []);
    _set(prevAppearanceSettings, 'report.general.results.query.resourcesNew', undefined);
    _set(prevAppearanceSettings, 'report.general.results.query.games', []);
    _set(prevAppearanceSettings, 'report.general.results.query.locations', []);
    _set(prevAppearanceSettings, 'report.general.results.query.locationsNew', undefined);

    _set(prevAppearanceSettings, 'report.geo.results.query.resources', []);
    _set(prevAppearanceSettings, 'report.geo.results.query.resourcesNew', undefined);
    _set(prevAppearanceSettings, 'report.geo.results.query.games', []);
    _set(prevAppearanceSettings, 'report.geo.results.query.locations', []);
    _set(prevAppearanceSettings, 'report.geo.results.query.locationsNew', undefined);
    _set(prevAppearanceSettings, 'report.geo.results.chart.resources', []);
    _set(prevAppearanceSettings, 'report.geo.results.chart.resourcesNew', undefined);
    _set(prevAppearanceSettings, 'report.geo.results.chart.games', []);
    _set(prevAppearanceSettings, 'report.geo.results.chart.locations', []);
    _set(prevAppearanceSettings, 'report.geo.results.chart.locationsNew', undefined);

    _set(prevAppearanceSettings, 'documentationArea.query.games', []);
    _set(prevAppearanceSettings, 'documentationArea.query.language', []);
    _set(prevAppearanceSettings, 'documentationArea.query.documentType', []);
    _set(prevAppearanceSettings, 'documentationArea.query.countries', []);

    const boards = _get(prevAppearanceSettings, 'dashboard.boards', []);
    const parsedBoards = boards.map((board) => {
      const boardGames = _get(board, 'query.games');
      const parsedGames = boardGames.reduce((accumulator, game) => {
        game.games?.forEach((gameId) => {
          accumulator.push({
            value: gameId,
            tag: game.tag,
          });
        });

        return accumulator;
      }, []);

      _set(board, 'query.games', parsedGames);

      return board;
    });
    _set(prevAppearanceSettings, 'dashboard.boards', parsedBoards);

    return (prevAppearanceSettings as unknown) as TNewAppearanceSettingsShape;
  },
};
