interface IChartDefinitionStyle {
  size: number;
  zIndex: number;
  height: number;
}
interface IChartDefinition {
  dataSource: {
    apiUrl: string;
  };
  style: IChartDefinitionStyle;
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[] }[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[] }[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettingsShape,
      dashboard: {
        ...prevAppearanceSettingsShape.dashboard,
        boards: prevAppearanceSettingsShape.dashboard.boards.map((board) => {
          return {
            ...board,
            charts: [
              {
                id: 1,
                name: 'ggr',
                chartType: 'area',
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/ggr-total-period',
                },
                style: {
                  size: 4,
                  zIndex: 1301,
                  height: 500,
                },
                resources: [],
                resourcesEnabled: true,
                games: [],
                gamesEnabled: true,
                locations: [],
                locationsEnabled: true,
                availableMetricUnits: ['ggr', 'bets', 'wins', 'rounds', 'macroRounds', 'gamePlayers', 'uniqueUsers'],
                selectedMetricUnit: 'ggr',
                availableDateUnits: ['month', 'day', 'hour'],
                selectedDateUnit: 'day',
                cumulative: false,
                cumulativeAvailable: false,
                compareDatePeriodsAvailable: true,
                compareDatePeriodsEnabled: false,
                dateRanges: [
                  {
                    startDate: '2021-11-30T22:00:00.000Z',
                    endDate: '2021-12-31T21:59:59.999Z',
                    schema: {
                      id: '7',
                      name: 'This month',
                      type: 'DYNAMIC_PERIOD',
                      period: 'MONTHLY',
                      condition: 'CURRENT_MONTH',
                      enabled: true,
                    },
                  },
                ],
                sortOrder: 100,
              },
              {
                id: 4,
                name: 'cumulative',
                chartType: 'line',
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/ggr-total-period',
                },
                style: {
                  size: 4,
                  zIndex: 1301,
                  height: 500,
                },
                resources: [],
                resourcesEnabled: true,
                games: [],
                gamesEnabled: true,
                locations: [],
                locationsEnabled: true,
                availableMetricUnits: ['ggr', 'bets', 'wins', 'rounds', 'macroRounds', 'gamePlayers', 'uniqueUsers'],
                selectedMetricUnit: 'ggr',
                availableDateUnits: ['month', 'day', 'hour'],
                selectedDateUnit: 'day',
                cumulative: true,
                cumulativeAvailable: false,
                compareDatePeriodsAvailable: true,
                compareDatePeriodsEnabled: false,
                dateRanges: [
                  {
                    startDate: '2021-11-30T22:00:00.000Z',
                    endDate: '2021-12-31T21:59:59.999Z',
                    schema: {
                      id: '7',
                      name: 'This month',
                      type: 'DYNAMIC_PERIOD',
                      period: 'MONTHLY',
                      condition: 'CURRENT_MONTH',
                      enabled: true,
                    },
                  },
                ],
                sortOrder: 400,
              },
              ({
                id: 2,
                name: 'games',
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/top-games-period',
                },
                style: {
                  size: 2,
                  zIndex: 1301,
                  height: 500,
                },
                chartType: 'pie',
                resources: [],
                resourcesEnabled: true,
                games: [],
                gamesEnabled: false,
                locations: [],
                locationsEnabled: true,
                competitorsCount: 5,
                competitorsCountAvailable: [5, 10, 15],
                availableEntityUnits: ['games'],
                selectedEntityUnit: 'games',
                availableMetricUnits: ['ggr', 'bets', 'wins', 'rounds', 'macroRounds', 'gamePlayers'],
                selectedMetricUnit: 'ggr',
                dateRanges: [
                  {
                    startDate: '2021-11-30T22:00:00.000Z',
                    endDate: '2021-12-31T21:59:59.999Z',
                    schema: {
                      id: '7',
                      name: 'This month',
                      type: 'DYNAMIC_PERIOD',
                      period: 'MONTHLY',
                      condition: 'CURRENT_MONTH',
                      enabled: true,
                    },
                  },
                ],
                sortOrder: 200,
                displayOthers: true,
              } as unknown) as IChartDefinition,
            ] as IChartDefinition[],
          };
        }),
      },
    };
  },
};
