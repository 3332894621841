const prevAppearanceSettingsShape = {
  report: {
    general: {
      appearance: {},
      results: {
        query: {
          timezone: {
            title: 'UTC +00:00',
            value: 'UTC',
          },
        },
      },
    },
    geo: {
      appearance: {},
      results: {
        query: {
          timezone: {
            title: 'UTC +00:00',
            value: 'UTC',
          },
        },
        chart: {
          timezone: {
            title: 'UTC +00:00',
            value: 'UTC',
          },
        },
      },
    },
  },
  sessionsReport: {
    appearance: {},
    view: {},
    results: {
      query: {
        timezone: {
          title: 'UTC +00:00',
          value: 'UTC',
        },
      },
    },
  },
};
const newAppearanceSettingsShape = {
  report: {
    general: {
      appearance: {},
      results: {
        query: {
          timezone: {
            title: 'Universal Coordinated Time (+00:00)',
            alternativeTitle: 'UTC (+00:00)',
            value: 'UTC',
          },
        },
      },
    },
    geo: {
      appearance: {},
      results: {
        query: {
          timezone: {
            title: 'Universal Coordinated Time (+00:00)',
            alternativeTitle: 'UTC (+00:00)',
            value: 'UTC',
          },
        },
        chart: {
          timezone: {
            title: 'Universal Coordinated Time (+00:00)',
            alternativeTitle: 'UTC (+00:00)',
            value: 'UTC',
          },
        },
      },
    },
  },
  sessionsReport: {
    appearance: {},
    view: {},
    results: {
      query: {
        timezone: {
          title: 'Universal Coordinated Time (+00:00)',
          alternativeTitle: 'UTC (+00:00)',
          value: 'UTC',
        },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettings,
      report: {
        ...prevAppearanceSettings.report,
        general: {
          ...prevAppearanceSettings.report.general,
          results: {
            ...prevAppearanceSettings.report.general.results,
            query: {
              ...prevAppearanceSettings.report.general.results.query,
              timezone: {
                title: 'Universal Coordinated Time (+00:00)',
                alternativeTitle: 'UTC (+00:00)',
                value: 'UTC',
              },
            },
          },
        },
        geo: {
          ...prevAppearanceSettings.report.geo,
          results: {
            ...prevAppearanceSettings.report.geo.results,
            query: {
              ...prevAppearanceSettings.report.geo.results.query,
              timezone: {
                title: 'Universal Coordinated Time (+00:00)',
                alternativeTitle: 'UTC (+00:00)',
                value: 'UTC',
              },
            },
            chart: {
              ...prevAppearanceSettings.report.geo.results.chart,
              timezone: {
                title: 'Universal Coordinated Time (+00:00)',
                alternativeTitle: 'UTC (+00:00)',
                value: 'UTC',
              },
            },
          },
        },
      },
      sessionsReport: {
        ...prevAppearanceSettings.sessionsReport,
        results: {
          ...prevAppearanceSettings.sessionsReport.results,
          query: {
            ...prevAppearanceSettings.sessionsReport.results.query,
            timezone: {
              title: 'Universal Coordinated Time (+00:00)',
              alternativeTitle: 'UTC (+00:00)',
              value: 'UTC',
            },
          },
        },
      },
    };
  },
};
