import _get from 'lodash/get';
import _set from 'lodash/set';
import _capitalize from 'lodash/capitalize';

const prevAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  report: {
    general: {
      test: 'data',
    },
    geo: {
      results: {
        query: {
          test: 'data',
        },
        chart: {
          resources: [],
          selectedMetricUnit: 'bets',
        },
      },
    },
  },
};
const newAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  report: {
    general: {
      test: 'data',
    },
    geo: {
      results: {
        query: {
          test: 'data',
        },
        chart: {
          resources: [],
          selectedMetricUnit: {
            title: 'Bets',
            value: 'bets',
          },
        },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const selectedMetricUnit: string = _get(prevAppearanceSettings, 'report.geo.results.chart.selectedMetricUnit', 'bets');

    if (typeof selectedMetricUnit === 'string') {
      _set(prevAppearanceSettings, 'report.geo.results.chart.selectedMetricUnit', {
        title: _capitalize(selectedMetricUnit),
        value: selectedMetricUnit,
      });
    }

    return (prevAppearanceSettings as unknown) as TNewAppearanceSettingsShape;
  },
};
