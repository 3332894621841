import { LicenseManager } from '@ag-grid-enterprise/core';

import envConfig from 'x-common/utils/envConfig';

import 'autotrack/lib/plugins/page-visibility-tracker';
import 'autotrack/lib/plugins/max-scroll-tracker';
import 'autotrack/lib/plugins/impression-tracker';
import 'autotrack/lib/plugins/event-tracker';
import 'autotrack/lib/plugins/clean-url-tracker';
import 'autotrack/lib/plugins/url-change-tracker';
import { datadogErrorFilter } from './datadogErrorFilter';

const GOOGLE_ANALYTICS_TOKEN = 'UA-179411669-1';
export const initGoogleAutoTrack = (): void => {
  const { ga } = window;

  ga('create', GOOGLE_ANALYTICS_TOKEN, {
    cookieDomain: 'auto',
    cookieFlags: 'HttpOnly;Secure',
  });

  ga('require', 'pageVisibilityTracker');
  ga('require', 'maxScrollTracker');
  ga('require', 'impressionTracker');
  ga('require', 'eventTracker');
  ga('require', 'cleanUrlTracker');
  ga('require', 'urlChangeTracker');
};

const insertAgGridLicenseKey = () => {
  if (envConfig.agGridLicenseKey) {
    LicenseManager.setLicenseKey(envConfig.agGridLicenseKey);
  }
};

const initializeDataDog = () => {
  window.DD_RUM.onReady(() => {
    window.DD_RUM.init({
      clientToken: envConfig.datadogRUMClientToken,
      applicationId: envConfig.datadogRUMApplicationId,
      site: envConfig.datadogSite,
      service: envConfig.datadogRUMService,
      env: envConfig.datadogRUMEnv,
      version: envConfig.appVersion,
      useSecureSessionCookie: true,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackUserInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'allow',
      beforeSend: datadogErrorFilter,
      allowedTracingOrigins: ['https://viewboard.app', /https:\/\/.*\.viewboard\.app/],
    });
  });
};

export default (): void => {
  initializeDataDog();
  insertAgGridLicenseKey();

  if (envConfig.isProductionBuild) {
    initGoogleAutoTrack();
  }

  // add other integrations here if required
};
