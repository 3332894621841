const prevAppearanceSettingsShape = {
  report: {
    geo: {
      results: {
        query: {
          folding: [{ title: 'Country', value: 'country' }],
        },
      },
    },
  },
};
const nextAppearanceSettingsShape = {
  report: {
    geo: {
      results: {
        query: {
          folding: [{ title: 'Country', value: 'countryName' }],
        },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNextAppearanceSettingsShape = typeof nextAppearanceSettingsShape;

export default {
  up: async (appearanceSettings: TPrevAppearanceSettingsShape): Promise<TNextAppearanceSettingsShape> => {
    const { report, ...restSettings } = appearanceSettings;

    return {
      ...restSettings,
      report: {
        ...report,
        geo: {
          ...report.geo,
          results: {
            ...report.geo.results,
            query: {
              ...report.geo.results.query,
              folding: [{ title: 'Country', value: 'countryName' }],
            },
          },
        },
      },
    };
  },
};
