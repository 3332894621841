const prevAppearanceSettingsShape = {
  report: {
    general: {
      appearance: {
        columnDefs: [],
      },
    },
    geo: {
      appearance: {
        columnDefs: [],
      },
    },
  },
};
const nextAppearanceSettingsShape = {
  report: {
    general: {
      appearance: {
        columnDefs: [],
      },
    },
    geo: {
      appearance: {
        columnDefs: [],
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNextAppearanceSettingsShape = typeof nextAppearanceSettingsShape;

export default {
  up: async (appearanceSettings: TPrevAppearanceSettingsShape): Promise<TNextAppearanceSettingsShape> => {
    const { report, ...restSettings } = appearanceSettings;

    return {
      ...restSettings,
      report: {
        ...report,
        general: {
          ...report.general,
          appearance: {
            ...report.general.appearance,
            columnDefs: [],
          },
        },
        geo: {
          ...report.geo,
          appearance: {
            ...report.geo.appearance,
            columnDefs: [],
          },
        },
      },
    };
  },
};
