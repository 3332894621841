// eslint-disable-next-line import/prefer-default-export
export const datadogErrorFilter = (event: { type: string; error: Error }) => {
  const { type, error } = event;
  // TODO: remove this once the issue is fixed
  // https://github.com/DataDog/browser-sdk/issues/2715
  const ignoreErrorMessagePattern = /Object Not Found Matching Id:\d+, MethodName:update, ParamCount:\d+/;
  if (type === 'error' && ignoreErrorMessagePattern.test(error.message)) {
    return false;
  }
  return true;
};
