/* eslint-disable camelcase */
import { RecordOf } from 'immutable';

import apiClient from 'apiClient';
import { StorageAdapterAWSS3 } from 'x-common/services/appearance/storageAdapters/awsS3/StorageAdapterAWSS3';
import AppearanceService from 'x-common/services/appearance/AppearanceService';

import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';
import widgetId, {
  // eslint-disable-next-line camelcase
  deprecated_widgetId,
} from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/widgetId';
import { getObject } from 'x-common/services/appearance/components/AppearanceStore/hooks/useGetObject';
import { putObject } from 'x-common/services/appearance/components/AppearanceStore/hooks/usePutObject';
import getJoiSchema from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/getJoiSchema';
// eslint-disable-next-line max-len
import getObjectFormatter from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/getObjectFormatter';
import { shortId } from 'x-common/utils/nanoid';

interface IChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IBoard {
  id: string;
  charts: IChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

const objectNormalizer = <
  ChartDefinitionFormData extends IChartDefinitionFormData = IChartDefinitionFormData,
  ChartDefinition extends IChartDefinition = IChartDefinition
>(
  chartDefinition: RecordOf<ChartDefinitionFormData>,
) => {
  return chartDefinition.toJS() as ChartDefinition;
};

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormData = IChartDefinitionFormData,
    ChartDefinition extends IChartDefinition = IChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    interface IBoardInternal {
      id: IBoard['id'];
      charts: ChartDefinition[];
    }
    const storageAdapter = new StorageAdapterAWSS3({ apiClient });
    const appearanceService = new AppearanceService({ storageAdapter });
    const boards: IBoardInternal[] = await prevAppearanceSettings.dashboard.boards.reduce((acc, board) => {
      const deprecated_boardId = board.id;
      const boardId =
        ((deprecated_boardId as unknown) as string) === 'DEFAULT_BOARD' ? `default_board_${shortId()}` : deprecated_boardId;
      return acc.then((dashboard) => {
        const charts: IBoardInternal['charts'] = [];
        return board.charts
          .reduce((acc, chartDefinition) => {
            const objectFormatter = getObjectFormatter(chartDefinition.chartType) as (
              object: ChartDefinition,
            ) => RecordOf<ChartDefinitionFormData>;
            const joiSchema = getJoiSchema<any>(chartDefinition.chartType);
            return acc
              .then(() => {
                const objectId = deprecated_widgetId(deprecated_boardId, chartDefinition.id);
                return getObject<ChartDefinition, RecordOf<ChartDefinitionFormData>>({
                  appearanceService,
                  objectId,
                  joiSchema,
                  objectFormatter,
                });
              })
              .then((formattedObject) => {
                const objectId = widgetId(boardId, chartDefinition.id);
                return putObject<ChartDefinition, RecordOf<ChartDefinitionFormData>>(formattedObject, {
                  appearanceService,
                  objectId,
                  joiSchema,
                  objectNormalizer,
                  objectFormatter,
                });
              })
              .then(() => {
                const lazyChartDefinition = {
                  id: chartDefinition.id,
                  chartType: chartDefinition.chartType,
                } as ChartDefinition;
                charts.push(lazyChartDefinition);
                return charts;
              });
          }, Promise.resolve<ChartDefinition[]>(charts))
          .then((charts) => {
            dashboard.push({
              ...board,
              id: boardId as IBoardInternal['id'],
              charts,
            });
            return dashboard;
          });
      });
    }, Promise.resolve<IBoardInternal[]>([]));
    return {
      ...prevAppearanceSettings,
      dashboard: {
        ...prevAppearanceSettings.dashboard,
        boards,
      },
    };
  },
};
