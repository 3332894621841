import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import loadable from '@loadable/component';

import RouterContextProvider from 'x-common/contexts/RouterContext';
import { ROUTE_INITIAL, ABSOLUTE_ROUTE_WELCOME } from 'x-common/bundles/AuthBundle/routes/ui';

import history from 'x-common/utils/browserHistory';

const AdminLayoutConnectedAsync = loadable(() => import('layouts/AdminLayoutConnected'));
const AuthLayoutConnectedAsync = loadable(() => import('layouts/AuthLayoutConnected'));
const ShortenerLayoutAsync = loadable(() => import('x-common/layouts/ShortenerLayout'));
const WelcomeLayoutAsync = loadable(() => import('x-common/layouts/WelcomeLayout'));

const App = () => (
  <ConnectedRouter history={history}>
    <Router history={history}>
      <RouterContextProvider>
        <Switch>
          <Route path="/shortener/:code" component={ShortenerLayoutAsync} />
          <Route path={ABSOLUTE_ROUTE_WELCOME} component={WelcomeLayoutAsync} />
          <Route path="/admin" component={AdminLayoutConnectedAsync} />
          <Route path="/auth" component={AuthLayoutConnectedAsync} />
          <Redirect from="/" to={ROUTE_INITIAL} />
        </Switch>
      </RouterContextProvider>
    </Router>
  </ConnectedRouter>
);

export default App;
