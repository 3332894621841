const prevAppearanceSettingsShape = {
  report: {
    general: {
      some: 'test',
      data: true,
    },
    geo: {
      some: 'test',
      data: true,
    },
  },
};
const newAppearanceSettingsShape = {
  report: {
    general: {
      some: 'test',
      data: true,
    },
    geo: {
      some: 'test',
      data: true,
    },
    sessions: {
      appearance: {
        rowSize: 'thick',
        columnDefs: [],
      },
      results: {
        query: {
          folding: [],
          games: [],
          grouping: {
            title: 'WLCode',
            value: 'wlcode',
          },
          resources: [],
          playersType: {
            title: 'Real',
            value: 'real',
          },
          dateRange: {
            startDate: '2021-02-28T22:00:00.000Z',
            endDate: '2021-03-31T20:59:59.999Z',
            schema: {
              id: '7',
              name: 'This month',
              type: 'DYNAMIC_PERIOD',
              period: 'MONTHLY',
              condition: 'CURRENT_MONTH',
              enabled: true,
            },
          },
          currency: {
            title: 'EUR',
            value: 'EUR',
          },
          platform: {
            title: 'All',
            value: 'all',
            disabled: false,
          },
          gameMode: {
            title: 'All',
            value: 'all',
            disabled: false,
          },
          excludePromoPlayers: false,
          locations: [],
        },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const { report, ...restSettings } = prevAppearanceSettingsShape;

    return {
      ...restSettings,
      report: {
        ...report,
        sessions: {
          appearance: {
            rowSize: 'thick',
            columnDefs: [],
          },
          results: {
            query: {
              folding: [],
              games: [],
              grouping: {
                title: 'WLCode',
                value: 'wlcode',
              },
              resources: [],
              playersType: {
                title: 'Real',
                value: 'real',
              },
              dateRange: {
                startDate: '2021-02-28T22:00:00.000Z',
                endDate: '2021-03-31T20:59:59.999Z',
                schema: {
                  id: '7',
                  name: 'This month',
                  type: 'DYNAMIC_PERIOD',
                  period: 'MONTHLY',
                  condition: 'CURRENT_MONTH',
                  enabled: true,
                },
              },
              currency: {
                title: 'EUR',
                value: 'EUR',
              },
              platform: {
                title: 'All',
                value: 'all',
                disabled: false,
              },
              gameMode: {
                title: 'All',
                value: 'all',
                disabled: false,
              },
              excludePromoPlayers: false,
              locations: [],
            },
          },
        },
      },
    };
  },
};
