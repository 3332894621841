/* eslint-disable camelcase */
import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';
import migrationWidgets from 'migrations/utils/migrationWidgets';

interface IChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IBoard {
  id: string;
  charts: IChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export const templatesUsingGeneralReport = [
  'LINE_BETS_THIS_MONTH',
  'LINE_BETS_THIS_MONTH_VS_LAST_MONTH',
  'LINE_BETS_LAST_28_DAYS_VS_PREVIOUS_28_DAYS',
  'LINE_BETS_CURRENT_Q_VS_LAST_Q',
  'LINE_BETS_CUMULATIVE_THIS_MONTH',
  'LINE_BETS_CUMULATIVE_THIS_MONTH_VS_LAST_MONTH',
  'LINE_BETS_CUMULATIVE_LAST_28_DAYS_VS_PREVIOUS_28_DAYS',
  'LINE_BETS_CUMULATIVE_CURRENT_Q_VS_LAST_Q',
];

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormData = IChartDefinitionFormData,
    ChartDefinition extends IChartDefinition = IChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(prevAppearanceSettings, (formattedObject) => {
      const shouldUpdate = templatesUsingGeneralReport.some((templateName) => {
        return formattedObject.get('template') === templateName;
      });
      if (shouldUpdate) {
        return formattedObject.set(
          'dataSource',
          formattedObject.get('dataSource')!.set('apiUrl', '/v1/report/transactions'),
        );
      }
      return formattedObject;
    });
  },
};
