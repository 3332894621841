import { fromJS } from 'immutable';

import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';

import migrationWidgets from 'migrations/utils/migrationWidgets';

interface ILazyChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IChartDefinitionFormDataMigration extends IChartDefinitionFormData {
  locationsEnabled: true;
}
interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormDataMigration = IChartDefinitionFormDataMigration,
    ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(
      prevAppearanceSettings,
      (formattedObject) => {
        // number 3 is symbolizes that the widget is already migrated
        if (
          formattedObject.get('games') &&
          formattedObject.get('games').size &&
          Object.keys(formattedObject.get('games').toJS()[0]).length > 3
        ) {
          const result: any = [];

          formattedObject.get('games').forEach((game) => {
            (game as any).get('games').forEach((gameId) => {
              result.push({ value: gameId, tag: game.get('tag') });
            });
          });

          return formattedObject.set('games', fromJS(result));
        }

        return formattedObject;
      },
      { ignoreJoiValidation: true },
    );
  },
};
