/* eslint-disable camelcase */

interface IWidgetDataGrid {
  cols: 12;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  zIndex: number;
}

interface ILazyChartDefinition {
  id: string;
  chartType: 'area';
}
interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
  layout: IWidgetDataGrid[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettings,
      dashboard: {
        ...prevAppearanceSettings.dashboard,
        boards: prevAppearanceSettings.dashboard.boards.map((board) => {
          const indices = board.charts.reduce((acc, chartDefinition, index) => {
            if (chartDefinition.chartType === 'area') {
              acc.push(index);
              return acc;
            }
            return acc;
          }, [] as number[]);
          const layout = board.layout.map((dataGrid, index) => {
            if (!indices.includes(index)) {
              return dataGrid;
            }
            if (dataGrid.minW === 2 && dataGrid.maxW === 2 && dataGrid.minH === 1 && dataGrid.maxH === 1) {
              return {
                ...dataGrid,
                minW: 4,
                maxW: 12,
                minH: 1,
                maxH: 3,
              };
            }
            return dataGrid;
          });
          return {
            ...board,
            layout,
          };
        }),
      },
    };
  },
};
