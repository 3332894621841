const prevAppearanceSettingsShape = {
  user: {
    users: {
      query: {
        viewVariant: 'CARD',
      },
    },
  },
};
const nextAppearanceSettingsShape = {
  user: {
    users: {
      query: {
        viewVariant: { value: 'CARD', icon: {} },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNextAppearanceSettingsShape = typeof nextAppearanceSettingsShape;

export default {
  up: async (appearanceSettings: TPrevAppearanceSettingsShape): Promise<TNextAppearanceSettingsShape> => {
    const { user, ...restSettings } = appearanceSettings;

    if (typeof user.users.query.viewVariant === 'string') {
      return {
        ...restSettings,
        user: {
          ...user,
          users: {
            ...user.users,
            query: {
              ...user.users.query,
              viewVariant: { value: user.users.query.viewVariant, icon: {} },
            },
          },
        },
      };
    }

    return (appearanceSettings as unknown) as TNextAppearanceSettingsShape;
  },
};
