/* eslint-disable camelcase */
import { List } from 'immutable';
import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';
import migrationWidgets from 'migrations/utils/migrationWidgets';

interface ILazyChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IChartDefinitionFormDataMigration extends IChartDefinitionFormData {
  availableMetricUnits: List<string>;
  selectedMetricUnit: string | null;
}
interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export const affectedTemplates = [
  'LINE_BETS_CUMULATIVE_THIS_MONTH',
  'LINE_BETS_CUMULATIVE_LAST_28_DAYS_VS_PREVIOUS_28_DAYS',
  'LINE_BETS_CUMULATIVE_THIS_MONTH_VS_LAST_MONTH',
  'LINE_BETS_CUMULATIVE_CURRENT_Q_VS_LAST_Q',
];
export const prohibitedUniqueMetricUnitsAsCumulative = ['gamePlayers', 'uniqueUsers'];

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormDataMigration = IChartDefinitionFormDataMigration,
    ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(prevAppearanceSettings, (formattedObject) => {
      const shouldUpdate = affectedTemplates.some((templateName) => {
        return formattedObject.get('template') === templateName;
      });
      if (!shouldUpdate) {
        return formattedObject;
      }
      const availableMetricUnits = formattedObject.get('availableMetricUnits');
      const selectedMetricUnit = formattedObject.get('selectedMetricUnit');
      const filteredMetricUnits = availableMetricUnits.filter(
        (metricUnit) => !prohibitedUniqueMetricUnitsAsCumulative.includes(metricUnit),
      );
      const reselectedMetricUnit = prohibitedUniqueMetricUnitsAsCumulative.includes(selectedMetricUnit!)
        ? 'bets'
        : selectedMetricUnit;
      return formattedObject
        .set('availableMetricUnits', filteredMetricUnits)
        .set('selectedMetricUnit', reselectedMetricUnit);
    });
  },
};
