import _get from 'lodash/get';
import _merge from 'lodash/merge';

import DateRangeBuilder from 'x-common/services/DateRangeBuilder';
import { DEFAULT_MOMENT_RANGE_SHORTCUTS, EDateRanges } from 'x-common/services/DateRangePresets';

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: '1',
        name: 'test-board',
        query: { resources: [1, 2], dateRange: { startDate: 'startDate', endDate: 'endDate' } },
        charts: [],
        layout: [],
      },
      {
        id: '2',
        name: 'test-board-2',
        query: { resources: [3, 4] },
        charts: [],
        layout: [],
      },
      {
        id: '3',
        name: 'test-board-3',
        query: { resources: [5, 6], dateRange: [{ startDate: 'startDate', endDate: 'endDate' }] },
        charts: [],
        layout: [],
      },
    ],
    activeBoardIndex: 1,
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: '1',
        name: 'test-board',
        query: { resources: [1, 2], dateRange: [{ startDate: 'startDate', endDate: 'endDate' }] },
        charts: [],
        layout: [],
      },
      {
        id: '2',
        name: 'test-board-2',
        query: { resources: [3, 4], dateRange: [{ startDate: 'startDate', endDate: 'endDate' }] },
        charts: [],
        layout: [],
      },
      {
        id: '3',
        name: 'test-board-3',
        query: { resources: [5, 6], dateRange: [{ startDate: 'startDate', endDate: 'endDate' }] },
        charts: [],
        layout: [],
      },
    ],
    activeBoardIndex: 1,
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const dateRangeBuilder = new DateRangeBuilder(DEFAULT_MOMENT_RANGE_SHORTCUTS[EDateRanges.LAST_28_DAYS]());
    const dateRangeLast28Days = dateRangeBuilder.getPrebuiltDateRange();
    const dateRangePlainObject = {
      startDate: dateRangeLast28Days?.startDate.toDate(),
      endDate: dateRangeLast28Days?.endDate.toDate(),
      schema: dateRangeLast28Days?.schema,
    };
    return _merge(prevAppearanceSettings, {
      dashboard: {
        boards: _get(prevAppearanceSettings, ['dashboard', 'boards'], []).map((board) => {
          const dateRange = _get(board, ['query', 'dateRange']);
          if (Array.isArray(dateRange)) {
            return board;
          }
          return {
            ...board,
            query: {
              ...board.query,
              dateRange: [dateRange || dateRangePlainObject],
            },
          };
        }),
      },
    });
  },
};
