import moment from 'x-common/utils/moment';

const prevAppearanceSettingsShape = {
  report: {
    general: {
      some: 'test',
      data: true,
    },
    geo: {
      some: 'test',
      data: true,
    },
    sessions: {
      some: 'test',
      data: true,
      results: {
        query: {
          some: 'test',
          data: true,
          dateRange: {
            endDate: '2021-09-23T09:00:00.000Z',
            schema: null,
            startDate: '2021-09-22T09:00:00.000Z',
          },
        },
      },
    },
  },
};
const newAppearanceSettingsShape = {
  report: {
    general: {
      some: 'test',
      data: true,
    },
    geo: {
      some: 'test',
      data: true,
    },
    sessions: {
      some: 'test',
      data: true,
      results: {
        query: {
          some: 'test',
          data: true,
          dateRange: {
            startDate: moment().startOf('day').format(),
            endDate: moment().endOf('day').format(),
            schema: null,
          },
        },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const { report, ...restSettings } = prevAppearanceSettingsShape;

    return {
      ...restSettings,
      report: {
        ...report,
        sessions: {
          ...report.sessions,
          results: {
            query: {
              ...report.sessions.results.query,
              dateRange: {
                startDate: moment().startOf('day').format(),
                endDate: moment().endOf('day').format(),
                schema: null,
              },
            },
          },
        },
      },
    };
  },
};
