import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEqual from 'lodash/isEqual';

interface IBoard {
  id: string;
  query: {
    resources: Array<{ title: string; value: string | number; tag: string }>;
  };
  appearance: { toolbar: Record<string, { level: 2 | 3 }> };
}

const prevAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  dashboard: {
    boards: [
      {
        id: '1',
        query: {
          resources: [],
        },
        appearance: {
          toolbar: {
            resources: { level: 2 },
          },
        },
      },
    ] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  wlCodeConfiguration: {
    test: 'data',
  },
  dashboard: {
    boards: [
      {
        query: {
          resources: [],
          userId: { title: 'All Owners', value: 'all', color: 'primary' },
          region: [],
          regulation: [],
        },
        appearance: {
          toolbar: {
            resources: { level: 2 },
            userId: { level: 3 },
            region: { level: 3 },
            regulation: { level: 3 },
          },
        },
      },
    ],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const newOwnerValue = { title: 'All Owners', value: 'all', color: 'primary' };

    const boards = _get(prevAppearanceSettings, 'dashboard.boards', []);
    const parsedBoards = boards.map((board) => {
      if (_isEqual(_get(board, 'query.userId'), newOwnerValue)) {
        return board;
      }

      _set(board, 'query.userId', newOwnerValue);
      _set(board, 'query.region', []);
      _set(board, 'query.regulation', []);

      _set(board, 'appearance.toolbar.userId', { level: 3 });
      _set(board, 'appearance.toolbar.region', { level: 3 });
      _set(board, 'appearance.toolbar.regulation', { level: 3 });

      return board;
    });
    _set(prevAppearanceSettings, 'dashboard.boards', parsedBoards);

    return (prevAppearanceSettings as unknown) as TNewAppearanceSettingsShape;
  },
};
