import _get from 'lodash/get';
import _merge from 'lodash/merge';

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: '1',
        name: 'test-board',
        query: { dateRange: { startDate: 'startDate', endDate: 'endDate' } },
        charts: [],
        layout: [],
      },
    ],
  },
  report: {
    general: {
      appearance: {},
      results: {
        query: {
          dateRange: {
            startDate: 'startDate',
            endDate: 'endDate',
          },
        },
      },
    },
    geo: {
      appearance: {},
      results: {
        query: {
          dateRange: {
            startDate: 'startDate',
            endDate: 'endDate',
          },
        },
        chart: {},
      },
    },
  },
  sessionsReport: {
    appearance: {},
    view: {},
    results: {
      query: {
        dateRange: {
          startDate: 'startDate',
          endDate: 'endDate',
        },
      },
    },
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [
      {
        id: '1',
        name: 'test-board',
        query: { dateRange: [{ startDate: 'startDate', endDate: 'endDate' }] },
        charts: [],
        layout: [],
      },
    ],
  },
  report: {
    general: {
      appearance: {},
      results: {
        query: {
          dateRange: [
            {
              startDate: 'startDate',
              endDate: 'endDate',
            },
          ],
        },
      },
    },
    geo: {
      appearance: {},
      results: {
        query: {
          dateRange: [
            {
              startDate: 'startDate',
              endDate: 'endDate',
            },
          ],
        },
        chart: {},
      },
    },
  },
  sessionsReport: {
    appearance: {},
    view: {},
    results: {
      query: {
        dateRange: [
          {
            startDate: 'startDate',
            endDate: 'endDate',
          },
        ],
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return _merge(prevAppearanceSettings, {
      report: {
        general: {
          results: {
            query: {
              dateRange: [_get(prevAppearanceSettings, 'report.general.results.query.dateRange')],
            },
          },
        },
        geo: {
          results: {
            query: {
              dateRange: [_get(prevAppearanceSettings, 'report.geo.results.query.dateRange')],
            },
          },
        },
      },
      sessionsReport: {
        results: {
          query: {
            dateRange: [_get(prevAppearanceSettings, 'sessionsReport.results.query.dateRange')],
          },
        },
      },
      dashboard: {
        boards: _get(prevAppearanceSettings, 'dashboard.boards', []).map((board) => {
          return {
            ...board,
            query: {
              ...board?.query,
              dateRange: [_get(board, 'query.dateRange')],
            },
          };
        }),
      },
    });
  },
};
