import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';

import migrationWidgets from 'migrations/utils/migrationWidgets';

interface ILazyChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IChartDefinitionFormDataMigration extends IChartDefinitionFormData {
  selectedDateUnit: string;
  selectedMetricUnit: string;
  selectedEntityUnit: string;
  competitorsCount: number;
}
interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export const templatesToMakeAvailableGameReleases = [
  'LINE_BETS_BY_GAMES_LAST_28_DAYS',
  'LINE_BETS_BY_PROJECT_LAST_28_DAYS',
  'LINE_BETS_BY_WL_LAST_28_DAYS',
];
export const templatesToEnableGameReleases = [
  'LINE_BETS_CUMULATIVE_CURRENT_Q_VS_LAST_Q',
  'LINE_BETS_CUMULATIVE_LAST_28_DAYS_VS_PREVIOUS_28_DAYS',
  'LINE_BETS_CUMULATIVE_THIS_MONTH',
  'LINE_BETS_CUMULATIVE_THIS_MONTH_VS_LAST_MONTH',
  'LINE_BETS_CURRENT_Q_VS_LAST_Q',
  'LINE_BETS_LAST_28_DAYS_VS_PREVIOUS_28_DAYS',
  'LINE_BETS_THIS_MONTH',
  'LINE_BETS_THIS_MONTH_VS_LAST_MONTH',
];

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormDataMigration = IChartDefinitionFormDataMigration,
    ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(
      prevAppearanceSettings,
      (formattedObject) => {
        const shouldMakeAvailable = templatesToMakeAvailableGameReleases.some((templateName) => {
          return formattedObject.get('template') === templateName;
        });
        if (shouldMakeAvailable) {
          return formattedObject.set('gameReleasesEnabled' as keyof ChartDefinitionFormData, false as any);
        }

        const shouldUpdate = templatesToEnableGameReleases.some((templateName) => {
          return formattedObject.get('template') === templateName;
        });
        if (shouldUpdate) {
          return formattedObject.set('gameReleasesEnabled' as keyof ChartDefinitionFormData, true as any);
        }

        return formattedObject;
      },
      { ignoreJoiValidation: true },
    );
  },
};
