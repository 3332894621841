interface IChartDefinition {
  dataSource: {
    apiUrl: string;
  };
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[]; layout: any[] }[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[]; layout: any[] }[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettingsShape,
      dashboard: {
        ...prevAppearanceSettingsShape.dashboard,
        boards: prevAppearanceSettingsShape.dashboard.boards.map((board) => {
          return {
            ...board,
            charts: [
              {
                ...board.charts[0],
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/ggr-total-period',
                },
              },
              {
                ...board.charts[1],
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/ggr-total-period',
                },
              },
              {
                ...board.charts[2],
                dataSource: {
                  apiUrl: '/v1/dashboard/finance/top-games-period',
                },
              },
            ] as IChartDefinition[],
            layout: [
              {
                x: 0,
                y: 0,
                w: 8,
                h: 2,
                minW: 1,
                maxW: 8,
                minH: 1,
                maxH: 4,
              },
              {
                x: 0,
                y: 1,
                w: 4,
                h: 2,
                minW: 1,
                maxW: 8,
                minH: 1,
                maxH: 4,
              },
              {
                x: 5,
                y: 1,
                w: 4,
                h: 2,
                minW: 3,
                maxW: 8,
                minH: 2,
                maxH: 4,
              },
            ],
          };
        }),
      },
    };
  },
};
