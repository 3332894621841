/* eslint-disable camelcase */
import { RecordOf, List } from 'immutable';
import _isNil from 'lodash/isNil';

import apiClient from 'apiClient';
import { StorageAdapterAWSS3 } from 'x-common/services/appearance/storageAdapters/awsS3/StorageAdapterAWSS3';
import AppearanceService from 'x-common/services/appearance/AppearanceService';
import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { LazyChartDefinitionRecord } from 'x-common/services/filter/chart/types/LazyChartDefinition';
import { getObject } from 'x-common/services/appearance/components/AppearanceStore/hooks/useGetObject';
import { putObject } from 'x-common/services/appearance/components/AppearanceStore/hooks/usePutObject';

import widgetId from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/widgetId';
import getJoiSchema from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/getJoiSchema';
// eslint-disable-next-line max-len
import getObjectFormatter from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/getObjectFormatter';

import { TChartType } from 'x-common/components/Chart';

interface ILazyChartDefinition {
  id: string;
  chartType: TChartType;
  placeholder?: boolean;
  guideHandles?: string[];
}

interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

const objectNormalizer = <
  ChartDefinitionFormData extends IChartDefinitionFormData = IChartDefinitionFormData,
  ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
>(
  chartDefinition: RecordOf<ChartDefinitionFormData>,
) => {
  return chartDefinition.toJS() as ChartDefinition;
};

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormData = IChartDefinitionFormData,
    ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
    migrate: (formattedObject: RecordOf<ChartDefinitionFormData>) => RecordOf<ChartDefinitionFormData>,
    options: { ignorePlaceholderCharts?: boolean; ignoreJoiValidation?: boolean } = {
      ignorePlaceholderCharts: true,
      ignoreJoiValidation: false,
    },
  ): Promise<TNewAppearanceSettingsShape> => {
    interface IBoardInternal {
      id: IBoard['id'];
      charts: ChartDefinition[];
    }
    const storageAdapter = new StorageAdapterAWSS3({ apiClient });
    const appearanceService = new AppearanceService({ storageAdapter });
    const boards: IBoardInternal[] = await prevAppearanceSettings.dashboard.boards.reduce((acc, board) => {
      return acc.then((dashboard) => {
        const charts: IBoardInternal['charts'] = [];
        return board.charts
          .reduce((acc, chartDefinition) => {
            const objectId = widgetId(board.id, chartDefinition.id);
            const isChartPlaceholder = (chartDefinition.chartType as TChartType | 'NULL') === 'NULL';
            const objectFormatter = getObjectFormatter(chartDefinition.chartType) as (
              object: ChartDefinition,
            ) => RecordOf<ChartDefinitionFormData>;
            const joiSchema = getJoiSchema<any>(chartDefinition.chartType);
            return acc
              .then(() => {
                if (isChartPlaceholder) {
                  return Promise.resolve(
                    (LazyChartDefinitionRecord({
                      id: chartDefinition.id,
                      chartType: chartDefinition.chartType,
                      placeholder: chartDefinition.placeholder,
                      guideHandles: List(chartDefinition.guideHandles || []),
                    }) as unknown) as RecordOf<ChartDefinitionFormData>,
                  );
                }

                return getObject<ChartDefinition, RecordOf<ChartDefinitionFormData>>({
                  appearanceService,
                  objectId,
                  joiSchema: options.ignoreJoiValidation ? undefined : joiSchema,
                  objectFormatter,
                });
              })
              .then((formattedObject) => {
                if (isChartPlaceholder && options.ignorePlaceholderCharts) {
                  return Promise.resolve(formattedObject);
                }

                const migratedWidget = migrate(formattedObject);
                if (migratedWidget.equals(formattedObject)) {
                  return formattedObject;
                }
                return putObject<ChartDefinition, RecordOf<ChartDefinitionFormData>>(migratedWidget, {
                  appearanceService,
                  objectId,
                  joiSchema,
                  objectNormalizer,
                  objectFormatter,
                });
              })
              .then(() => {
                const lazyChartDefinition = {
                  id: chartDefinition.id,
                  chartType: chartDefinition.chartType,
                } as ChartDefinition;
                if (!_isNil(chartDefinition.guideHandles)) {
                  lazyChartDefinition.guideHandles = chartDefinition.guideHandles;
                }
                if (!_isNil(chartDefinition.placeholder)) {
                  lazyChartDefinition.placeholder = chartDefinition.placeholder;
                }
                charts.push(lazyChartDefinition);
                return charts;
              });
          }, Promise.resolve<ChartDefinition[]>(charts))
          .then((charts) => {
            dashboard.push({
              ...board,
              id: board.id as IBoardInternal['id'],
              charts,
            });
            return dashboard;
          });
      });
    }, Promise.resolve<IBoardInternal[]>([]));
    return {
      ...prevAppearanceSettings,
      dashboard: {
        ...prevAppearanceSettings.dashboard,
        boards,
      },
    };
  },
};
