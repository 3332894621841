import moment from 'x-common/utils/moment';

const prevAppearanceSettingsShape = {
  generalReport: {
    appearance: { rowSize: 'thick' },
    results: {
      query: {
        folding: [],
        games: [],
        grouping: { title: 'WLCode', value: 'wlcode' },
        resources: [],
        playersType: { title: 'Real', value: 'real' },
        dateRange: {
          startDate: '2019-12-31T22:00:00.000Z',
          endDate: '2020-12-31T21:59:59.999Z',
          schema: {
            period: 'MONTHLY',
            enabled: true,
            endDate: '2020-12-31T21:59:59.999Z',
            includeCurrentDay: true,
            name: 'Last year',
            startDate: '2019-12-31T22:00:00.000Z',
            amount: 1,
            type: 'DYNAMIC_PERIOD',
            id: 'ulT0dMdP_',
            condition: 'LAST_YEAR',
            description: '',
          },
        },
        currency: { title: 'EUR', value: 'EUR' },
        platform: { title: 'All', value: 'all', disabled: false },
        gameMode: { title: 'All', value: 'all', disabled: false },
        excludePromoPlayers: false,
        locations: [],
      },
    },
  },
};
const nextAppearanceSettingsShape = {
  report: {
    general: {
      appearance: { rowSize: 'thick' },
      results: {
        query: {
          folding: [],
          games: [],
          grouping: { title: 'WLCode', value: 'wlcode' },
          resources: [],
          playersType: { title: 'Real', value: 'real' },
          dateRange: {
            startDate: '2021-01-31T22:00:00.000Z',
            endDate: '2021-02-28T21:59:59.999Z',
            schema: {
              id: '7',
              name: 'This month',
              type: 'DYNAMIC_PERIOD',
              period: 'MONTHLY',
              condition: 'CURRENT_MONTH',
              enabled: true,
            },
          },
          currency: { title: 'EUR', value: 'EUR' },
          platform: { title: 'All', value: 'all', disabled: false },
          gameMode: { title: 'All', value: 'all', disabled: false },
          excludePromoPlayers: false,
          locations: [],
        },
      },
    },
    geo: {
      appearance: { rowSize: 'thick', viewType: 'map' },
      results: {
        query: {
          folding: [{ title: 'Country', value: 'country' }],
          games: [],
          grouping: { title: 'WLCode', value: 'wlcode' },
          resources: [],
          playersType: { title: 'Real', value: 'real' },
          dateRange: {
            startDate: '2021-01-31T22:00:00.000Z',
            endDate: '2021-02-28T21:59:59.999Z',
            schema: {
              id: '7',
              name: 'This month',
              type: 'DYNAMIC_PERIOD',
              period: 'MONTHLY',
              condition: 'CURRENT_MONTH',
              enabled: true,
            },
          },
          currency: { title: 'EUR', value: 'EUR' },
          platform: { title: 'All', value: 'all', disabled: false },
          gameMode: { title: 'All', value: 'all', disabled: false },
          excludePromoPlayers: false,
          locations: [],
        },
        chart: {
          gamesEnabled: true,
          games: [],
          locationsEnabled: true,
          dateRanges: [{ startDate: '2021-01-31T22:00:00.000Z', endDate: '2021-02-28T21:59:59.999Z' }],
          resources: [],
          name: 'geo',
          selectedMetricUnit: 'ggr',
          sortOrder: 200,
          availableMetricUnits: ['ggr', 'bets', 'wins', 'rounds', 'macroRounds', 'gamePlayers', 'uniqueUsers'],
          chartType: 'map',
          id: 5,
          resourcesEnabled: true,
          locations: [],
        },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNextAppearanceSettingsShape = typeof nextAppearanceSettingsShape;

export default {
  up: async (appearanceSettings: TPrevAppearanceSettingsShape): Promise<TNextAppearanceSettingsShape> => {
    const { generalReport, ...restSettings } = appearanceSettings;

    return {
      ...restSettings,
      report: {
        general: generalReport,
        geo: {
          appearance: { rowSize: 'thick', viewType: 'map' },
          results: {
            query: {
              folding: [{ title: 'Country', value: 'country' }],
              games: [],
              grouping: { title: 'WLCode', value: 'wlcode' },
              resources: [],
              playersType: { title: 'Real', value: 'real' },
              dateRange: {
                startDate: moment().startOf('month').toISOString(),
                endDate: moment().endOf('month').toISOString(),
                schema: {
                  id: '7',
                  name: 'This month',
                  type: 'DYNAMIC_PERIOD',
                  period: 'MONTHLY',
                  condition: 'CURRENT_MONTH',
                  enabled: true,
                },
              },
              currency: { title: 'EUR', value: 'EUR' },
              platform: { title: 'All', value: 'all', disabled: false },
              gameMode: { title: 'All', value: 'all', disabled: false },
              excludePromoPlayers: false,
              locations: [],
            },
            chart: {
              gamesEnabled: true,
              games: [],
              locationsEnabled: true,
              dateRanges: [
                { startDate: moment().startOf('month').toISOString(), endDate: moment().endOf('month').toISOString() },
              ],
              resources: [],
              name: 'geo',
              selectedMetricUnit: 'ggr',
              sortOrder: 200,
              availableMetricUnits: ['ggr', 'bets', 'wins', 'rounds', 'macroRounds', 'gamePlayers', 'uniqueUsers'],
              chartType: 'map',
              id: 5,
              resourcesEnabled: true,
              locations: [],
            },
          },
        },
      },
    };
  },
};
