import { TDateRangeGenericSchema } from 'x-common/services/DateRangeBuilder';
import DEFAULT_MOMENT_RANGE_SHORTCUTS from 'x-common/services/DateRangePresets';
import { IWidgetDataGrid } from 'x-common/services/WidgetDataGridService';

interface IChartDefinition {
  name: string;
  template: string;
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[]; layout: IWidgetDataGrid[] }[],
  },
  generalData: {
    dateRangeShortcuts: {
      list: [] as TDateRangeGenericSchema[],
    },
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as { charts: IChartDefinition[]; layout: IWidgetDataGrid[] }[],
  },
  generalData: {
    dateRangeShortcuts: {
      list: [] as TDateRangeGenericSchema[],
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettingsShape,
      dashboard: {
        ...prevAppearanceSettingsShape.dashboard,
        boards: prevAppearanceSettingsShape.dashboard.boards.map((board) => {
          return {
            ...board,
            charts: [
              {
                ...board.charts[0],
                name: 'Bets This Month',
                template: 'LINE_BETS_THIS_MONTH',
              },
              {
                ...board.charts[1],
                name: 'Bets Cumulative This Month',
                template: 'LINE_BETS_CUMULATIVE_THIS_MONTH',
              },
              {
                ...board.charts[2],
                name: 'Top 5 Games by Bets This Month',
                template: 'PIE_GAMES_BY_BETS_THIS_MONTH',
              },
            ] as IChartDefinition[],
            layout: [
              {
                cols: 12,
                y: 0,
                x: 0,
                w: 12,
                h: 2,
                minH: 1,
                maxH: 3,
                minW: 4,
                maxW: 12,
                zIndex: 1301,
              },
              {
                cols: 12,
                y: 1,
                x: 0,
                w: 6,
                h: 2,
                minH: 1,
                maxH: 3,
                minW: 4,
                maxW: 12,
                zIndex: 1301,
              },
              {
                cols: 12,
                y: 1,
                x: 6,
                w: 6,
                h: 2,
                minH: 2,
                maxH: 3,
                minW: 4,
                maxW: 12,
                zIndex: 1301,
              },
            ] as IWidgetDataGrid[],
          };
        }),
      },
      generalData: {
        ...prevAppearanceSettingsShape.generalData,
        dateRangeShortcuts: {
          ...prevAppearanceSettingsShape.generalData.dateRangeShortcuts,
          list: Object.values(DEFAULT_MOMENT_RANGE_SHORTCUTS).map((shortcut) => shortcut()),
        },
      },
    };
  },
};
