import _merge from 'lodash/merge';

const prevAppearanceSettingsShape = {
  report: {
    general: {
      appearance: {},
      results: {
        query: {
          excludePromoPlayers: false,
          platform: { title: 'All', value: 'all' },
          playersType: { title: 'Real', value: 'real' },
          folding: [{ title: 'test', value: 'test' }],
        },
      },
    },
    geo: {
      appearance: {
        viewType: 'table',
      },
      results: {
        query: {
          excludePromoPlayers: false,
          platform: { title: 'All', value: 'all' },
          playersType: { title: 'Real', value: 'real' },
        },
        chart: {},
      },
    },
  },
  sessionsReport: {
    appearance: {},
    results: {
      query: {
        platform: { title: 'All', value: 'all' },
        playersType: { title: 'Real', value: 'real' },
      },
    },
  },
};
const newAppearanceSettingsShape = {
  report: {
    general: {
      appearance: {},
      results: {
        query: {
          excludePromoPlayers: { title: 'All Users', value: 'INCLUDE' },
          platform: { title: 'All Devices', value: 'all' },
          playersType: { title: 'Real Money', value: 'real' },
        },
      },
    },
    geo: {
      appearance: {
        viewType: 'GRID',
      },
      results: {
        query: {
          excludePromoPlayers: { title: 'All Users', value: 'INCLUDE' },
          platform: { title: 'All Devices', value: 'all' },
          playersType: { title: 'Real Money', value: 'real' },
        },
        chart: {},
      },
    },
  },
  sessionsReport: {
    appearance: {},
    results: {
      query: {
        platform: { title: 'All Devices', value: 'all' },
        playersType: { title: 'Real Money', value: 'real' },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    const result = _merge({}, prevAppearanceSettings, {
      report: {
        general: {
          results: {
            query: {
              excludePromoPlayers: { title: 'All Users', value: 'INCLUDE' },
              platform: { title: 'All Devices', value: 'all' },
              playersType: { title: 'Real Money', value: 'real' },
            },
          },
        },
        geo: {
          appearance: {
            viewType: 'GRID',
          },
          results: {
            query: {
              excludePromoPlayers: { title: 'All Users', value: 'INCLUDE' },
              platform: { title: 'All Devices', value: 'all' },
              playersType: { title: 'Real Money', value: 'real' },
            },
          },
        },
      },
      sessionsReport: {
        results: {
          query: {
            platform: { title: 'All Devices', value: 'all' },
            playersType: { title: 'Real Money', value: 'real' },
          },
        },
      },
    });

    delete (result.report.general.results.query as { folding?: any }).folding;

    return result;
  },
};
