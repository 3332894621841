const prevAppearanceSettingsShape = {
  report: {
    geo: {
      results: {
        query: {},
        chart: {},
      },
    },
  },
};
const newAppearanceSettingsShape = {
  report: {
    geo: {
      results: {
        query: {},
        chart: {
          template: 'MAP_BUBBLE_BY_BETS_THIS_MONTH',
          name: 'Bets in World',
        },
      },
    },
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettingsShape,
      report: {
        ...prevAppearanceSettingsShape.report,
        geo: {
          ...prevAppearanceSettingsShape.report.geo,
          results: {
            ...prevAppearanceSettingsShape.report.geo.results,
            chart: {
              ...prevAppearanceSettingsShape.report.geo.results.chart,
              template: 'MAP_BUBBLE_BY_BETS_THIS_MONTH',
              name: 'Bets in World',
            },
          },
        },
      },
    };
  },
};
