/* eslint-disable camelcase */
import { shortId } from 'x-common/utils/nanoid';

interface IWidgetDataGrid {
  cols: 12;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  zIndex: number;
}

interface ILazyChartDefinition {
  id: string;
  chartType: 'NULL' | 'area';
}
interface IBoard {
  id: string;
  name: string;
  icon: string;
  charts: ILazyChartDefinition[];
  layout: IWidgetDataGrid[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettings: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    return {
      ...prevAppearanceSettings,
      dashboard: {
        ...prevAppearanceSettings.dashboard,
        boards: prevAppearanceSettings.dashboard.boards.map((board) => {
          const filledBoard = board.charts.length;
          if (filledBoard) {
            return board;
          }
          return {
            ...board,
            charts: [
              {
                id: shortId(),
                chartType: 'NULL',
                placeholder: true,
                guideHandles: ['ResizeHandle'],
              },
              {
                id: shortId(),
                chartType: 'NULL',
                placeholder: true,
                guideHandles: ['DraggableHandle'],
              },
              {
                id: shortId(),
                chartType: 'NULL',
                placeholder: true,
                guideHandles: ['ToggleFullscreenButton'],
              },
            ],
            layout: [
              {
                cols: 12,
                h: 2,
                maxH: 3,
                maxW: 12,
                minH: 1,
                minW: 4,
                w: 12,
                x: 0,
                y: 0,
                zIndex: 1301,
              },
              {
                cols: 12,
                h: 2,
                maxH: 3,
                maxW: 12,
                minH: 1,
                minW: 4,
                w: 6,
                x: 0,
                y: 1,
                zIndex: 1301,
              },
              {
                cols: 12,
                h: 2,
                maxH: 3,
                maxW: 12,
                minH: 1,
                minW: 4,
                w: 6,
                x: 6,
                y: 1,
                zIndex: 1301,
              },
            ],
          };
        }),
      },
    };
  },
};
