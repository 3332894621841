import _isNil from 'lodash/isNil';

import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';

import migrationWidgets from 'migrations/utils/migrationWidgets';

interface ILazyChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IChartDefinitionFormDataMigration extends IChartDefinitionFormData {
  locationsEnabled: true;
}
interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export const affectedTemplates = [
  'COLUMN_BETS_SUM_BY_WL_CURRENT_Q_VS_LAST_Q',
  'COLUMN_BETS_SUM_BY_PROJECT_CURRENT_Q_VS_LAST_Q',
  'COLUMN_BETS_SUM_BY_WL_CURRENT_Q_VS_LAST_Q',
  'COUNTER_BETS_THIS_MONTH',
  'LINE_BETS_CUMULATIVE_CURRENT_Q_VS_LAST_Q',
  'LINE_BETS_CUMULATIVE_LAST_28_DAYS_VS_PREVIOUS_28_DAYS',
  'LINE_BETS_CUMULATIVE_THIS_MONTH',
  'LINE_BETS_CUMULATIVE_THIS_MONTH_VS_LAST_MONTH',
  'LINE_BETS_CURRENT_Q_VS_LAST_Q',
  'LINE_BETS_LAST_28_DAYS_VS_PREVIOUS_28_DAYS',
  'LINE_BETS_THIS_MONTH',
  'LINE_BETS_THIS_MONTH_VS_LAST_MONTH',
];

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormDataMigration = IChartDefinitionFormDataMigration,
    ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(prevAppearanceSettings, (formattedObject) => {
      const shouldUpdate = affectedTemplates.some((templateName) => {
        return formattedObject.get('template') === templateName;
      });
      if (!shouldUpdate) {
        return formattedObject;
      }

      if (_isNil(formattedObject.get('dataSource')?.get('apiUrl'))) {
        return formattedObject;
      }

      return formattedObject.set(
        'dataSource',
        formattedObject.get('dataSource')!.set('apiUrl', '/v1/dashboard/report/transactions'),
      );
    });
  },
};
