import { isImmutable } from 'immutable';
import _capitalize from 'lodash/capitalize';

import { IChartDefinitionFormData } from 'x-common/services/filter/chart/types/ChartDefinition';
import { TChartType } from 'x-common/components/Chart';

import migrationWidgets from 'migrations/utils/migrationWidgets';

interface ILazyChartDefinition {
  id: string;
  chartType: TChartType;
}
interface IChartDefinitionFormDataMigration extends IChartDefinitionFormData {
  selectedDateUnit: string;
  selectedMetricUnit: string;
  selectedEntityUnit: string;
  competitorsCount: number;
}
interface IBoard {
  id: string;
  charts: ILazyChartDefinition[];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    boards: [] as IBoard[],
  },
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async <
    ChartDefinitionFormData extends IChartDefinitionFormDataMigration = IChartDefinitionFormDataMigration,
    ChartDefinition extends ILazyChartDefinition = ILazyChartDefinition
  >(
    prevAppearanceSettings: TPrevAppearanceSettingsShape,
  ): Promise<TNewAppearanceSettingsShape> => {
    return migrationWidgets.up<ChartDefinitionFormData, ChartDefinition>(
      prevAppearanceSettings,
      (formattedObject) => {
        if (formattedObject.get('selectedDateUnit')) {
          const selectedDateUnit = isImmutable(formattedObject.get('selectedDateUnit'))
            ? (formattedObject.get('selectedDateUnit') as any).toJS()
            : formattedObject.get('selectedDateUnit');

          if (typeof selectedDateUnit === 'object') {
            return formattedObject;
          }
        }
        if (formattedObject.get('selectedMetricUnit')) {
          const selectedMetricUnit = isImmutable(formattedObject.get('selectedMetricUnit'))
            ? (formattedObject.get('selectedMetricUnit') as any).toJS()
            : formattedObject.get('selectedMetricUnit');

          if (typeof selectedMetricUnit === 'object') {
            return formattedObject;
          }
        }
        if (formattedObject.get('competitorsCount')) {
          const competitorsCount = isImmutable(formattedObject.get('competitorsCount'))
            ? (formattedObject.get('competitorsCount') as any).toJS()
            : formattedObject.get('competitorsCount');

          if (typeof competitorsCount === 'object') {
            return formattedObject;
          }
        }
        if (formattedObject.get('selectedEntityUnit')) {
          const selectedEntityUnit = isImmutable(formattedObject.get('selectedEntityUnit'))
            ? (formattedObject.get('selectedEntityUnit') as any).toJS()
            : formattedObject.get('selectedEntityUnit');

          if (typeof selectedEntityUnit === 'object') {
            return formattedObject;
          }
        }

        if (formattedObject.get('selectedDateUnit')) {
          formattedObject = formattedObject.set('selectedDateUnit', {
            title: _capitalize(formattedObject.get('selectedDateUnit')),
            value: formattedObject.get('selectedDateUnit'),
          } as any);
        }

        if (formattedObject.get('selectedMetricUnit')) {
          formattedObject = formattedObject.set('selectedMetricUnit', {
            title: _capitalize(formattedObject.get('selectedMetricUnit')),
            value: formattedObject.get('selectedMetricUnit'),
          } as any);
        }

        if (formattedObject.get('competitorsCount')) {
          formattedObject = formattedObject.set('competitorsCount', {
            title: `Top ${formattedObject.get('competitorsCount')}`,
            value: formattedObject.get('competitorsCount'),
          } as any);
        }

        if (formattedObject.get('selectedEntityUnit')) {
          formattedObject = formattedObject.set('selectedEntityUnit', {
            title: _capitalize(formattedObject.get('selectedEntityUnit')),
            value: formattedObject.get('selectedEntityUnit'),
          } as any);
        }

        return formattedObject;
      },
      { ignoreJoiValidation: true },
    );
  },
};
