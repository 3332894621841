/* eslint-disable camelcase,max-len */
import Migrations from 'x-common/services/Migrations';

import migrationV2 from 'migrations/migrationV2';
import migrationV3 from 'migrations/migrationV3';
import migrationV4 from 'migrations/migrationV4';
import migrationV5 from 'migrations/migrationV5';
import migrationV6 from 'migrations/migrationV6';
import migrationV7 from 'migrations/migrationV7';
import migrationV8 from 'migrations/migrationV8';
import migrationV9 from 'migrations/migrationV9';
import migrationV10 from 'migrations/migrationV10';
import migrationV11 from 'migrations/migrationV11';
import migrationV12 from 'migrations/migrationV12';
import migrationV13 from 'migrations/migrationV13';
import migrationV14 from 'migrations/migrationV14';
import migrationV15 from 'migrations/migrationV15';
import migrationV16 from 'migrations/migrationV16';
import migrationV17 from 'migrations/migrationV17';
import migrationV18 from 'migrations/migrationV18';
import migrationV19 from 'migrations/migrationV19';
import migrationV20 from 'migrations/migrationV20';
import migrationV21_dashboard_widgets from 'migrations/migrationV21_dashboard_widgets';
import migrationV22_dashboard_widgets_hash_refresh from 'migrations/migrationV22_dashboard_widgets_hash_refresh';
import migrationV23_report_update_timezone_filter from 'migrations/migrationV23_report_update_timezone_filter';
import migrationV24_dashboard_widgets_compute_compare_date_ranges from 'migrations/migrationV24_dashboard_widgets_compute_compare_date_ranges';
import migrationV25_dashboard_widgets_using_general_report from 'migrations/migrationV25_dashboard_widgets_using_general_report';
import migrationV26_dashboard_widgets_cumulative_unique_metrics from 'migrations/migrationV26_dashboard_widgets_cumulative_unique_metrics';
import migrationV27_dashboard_widgets_layout from 'migrations/migrationV27_dashboard_widgets_layout';
import migrationV28_dashboard_refresh_blank_boards from 'migrations/migrationV28_dashboard_refresh_blank_boards';
import migrationV29_filterManager_update_dateRange_filter from 'migrations/migrationV29_filterManager_update_dateRange_filter';
import migrationV30_dashboard_whitescreen from 'migrations/migrationV30_dashboard_whitescreen';
import migrationV31_new_filter_values from 'migrations/migrationV31_new_filter_values';
import migrationV32_dashboard_widgets_add_toolbar_appearance from 'migrations/migrationV32_dashboard_widgets_add_toolbar_appearance';
import migrationV33_report_reset_unnecessary_filters from 'migrations/migrationV33_report_reset_unnecessary_filters';
import migrationV34_dashboard_widgets_enable_location_filter from 'migrations/migrationV34_dashboard_widgets_enable_location_filter';
import migrationV35_dashboard_apply_default_preset from 'migrations/migrationV35_dashboard_apply_default_preset';
import migrationV36_dashboard_widgets_change_data_source from 'migrations/migrationV36_dashboard_widgets_change_data_source';
import migrationV37_report_change_resource_values from 'migrations/migrationV37_report_change_resource_values';
import migrationV38_dashboard_widgets_simplify_resources from 'migrations/migrationV38_dashboard_widgets_simplify_resources';
import migrationv39_dashboard_pie_chart_change_entity from 'migrations/migrationv39_dashboard_pie_chart_change_entity';
import migrationV40_doc_area_reset_settings from 'migrations/migrationV40_doc_area_reset_settings';
import migrationV41_dashboard_toolbar_add_new_filters from 'migrations/migrationV41_dashboard_toolbar_add_new_filters';
import migrationV42_dashboard_widgets_new_filters from 'migrations/migrationV42_dashboard_widgets_new_filters';
import migrationV43_geo_report_change_map_filter_structure from 'migrations/migrationV43_geo_report_change_map_filter_structure';
import migrationV44_dashboard_add_game_release_prop from 'migrations/migrationV44_dashboard_add_game_release_prop';
import migrationV45_decrease_widgets_for_default_board from 'migrations/migrationV45_decrease_widgets_for_default_board';
import migrationV46_geo_report_recourses_new from 'migrations/migrationV46_geo_report_recourses_new';
import migrationV47_dashboard_widgets_cts_migration from 'migrations/migrationV47_dashboard_widgets_cts_migration';
import migrationV48_cts_filters_reset from 'migrations/migrationV48_cts_filters_reset';

export default new Migrations({
  actualVersion: 48,
  migrations: [
    migrationV2,
    migrationV3,
    migrationV4,
    migrationV5,
    migrationV6,
    migrationV7,
    migrationV8,
    migrationV9,
    migrationV10,
    migrationV11,
    migrationV12,
    migrationV13,
    migrationV14,
    migrationV15,
    migrationV16,
    migrationV17,
    migrationV18,
    migrationV19,
    migrationV20,
    migrationV21_dashboard_widgets,
    migrationV22_dashboard_widgets_hash_refresh,
    migrationV23_report_update_timezone_filter,
    migrationV24_dashboard_widgets_compute_compare_date_ranges,
    migrationV25_dashboard_widgets_using_general_report,
    migrationV26_dashboard_widgets_cumulative_unique_metrics,
    migrationV27_dashboard_widgets_layout,
    migrationV28_dashboard_refresh_blank_boards,
    migrationV29_filterManager_update_dateRange_filter,
    migrationV30_dashboard_whitescreen,
    migrationV31_new_filter_values,
    migrationV32_dashboard_widgets_add_toolbar_appearance,
    migrationV33_report_reset_unnecessary_filters,
    migrationV34_dashboard_widgets_enable_location_filter,
    migrationV35_dashboard_apply_default_preset,
    migrationV36_dashboard_widgets_change_data_source,
    migrationV37_report_change_resource_values,
    migrationV38_dashboard_widgets_simplify_resources,
    migrationv39_dashboard_pie_chart_change_entity,
    migrationV40_doc_area_reset_settings,
    migrationV41_dashboard_toolbar_add_new_filters,
    migrationV42_dashboard_widgets_new_filters,
    migrationV43_geo_report_change_map_filter_structure,
    migrationV44_dashboard_add_game_release_prop,
    migrationV45_decrease_widgets_for_default_board,
    migrationV46_geo_report_recourses_new,
    migrationV47_dashboard_widgets_cts_migration,
    migrationV48_cts_filters_reset,
  ],
});
