import _isEqual from 'lodash/isEqual';

import InitialBoardRecord from 'x-common/bundles/DashboardBundle/models/records/InitialBoardRecord';
import { putObject } from 'x-common/services/appearance/components/AppearanceStore/hooks/usePutObject';
import widgetId from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/widgetId';
import getJoiSchema from 'x-common/bundles/DashboardBundle/components/Board/containers/appearance/helpers/getJoiSchema';

import { StorageAdapterAWSS3 } from 'x-common/services/appearance/storageAdapters/awsS3/StorageAdapterAWSS3';
import AppearanceService from 'x-common/services/appearance/AppearanceService';

import apiClient from 'apiClient';

interface IChartDefinition {
  chartType: 'line' | 'pie';
  compareDatePeriodsAvailable: boolean;
  compareDatePeriodsEnabled: boolean;
  dateRanges: [object] | [object, object];
}

const prevAppearanceSettingsShape = {
  dashboard: {
    activeBoardIndex: 0,
    boards: [] as { charts: IChartDefinition[] }[],
  },
};
const newAppearanceSettingsShape = {
  dashboard: {
    activeBoardIndex: 0,
    boards: [] as Array<{ charts: IChartDefinition[] }>,
  },
};

const isBlankDashboard = (dashboard) => {
  const chartsOfBlankBoard = [
    {
      id: undefined,
      chartType: 'NULL',
      placeholder: true,
      guideHandles: ['ResizeHandle'],
    },
    {
      id: undefined,
      chartType: 'NULL',
      placeholder: true,
      guideHandles: ['DraggableHandle'],
    },
    {
      id: undefined,
      chartType: 'NULL',
      placeholder: true,
      guideHandles: ['ToggleFullscreenButton'],
    },
  ];

  return dashboard.boards.every((board) => {
    const boardChartsWithoutIds = board.charts.map((chart) => {
      return {
        ...chart,
        id: undefined,
      };
    });

    return _isEqual(boardChartsWithoutIds, chartsOfBlankBoard);
  });
};

export type TPrevAppearanceSettingsShape = typeof prevAppearanceSettingsShape;
export type TNewAppearanceSettingsShape = typeof newAppearanceSettingsShape;

export default {
  up: async (prevAppearanceSettingsShape: TPrevAppearanceSettingsShape): Promise<TNewAppearanceSettingsShape> => {
    if (prevAppearanceSettingsShape.dashboard && isBlankDashboard(prevAppearanceSettingsShape.dashboard)) {
      const storageAdapter = new StorageAdapterAWSS3({ apiClient });
      const appearanceService = new AppearanceService({ storageAdapter });

      // no manipulation needed to store initial board charts
      const objectNormalizer = (object) => object;
      const objectFormatter = (object) => object;

      const initialBoardRecord = InitialBoardRecord();

      const promises = initialBoardRecord
        .get('charts')
        .toJS()
        .map((chartDefinition) => {
          const joiSchema = getJoiSchema(chartDefinition.chartType);

          return putObject(chartDefinition, {
            objectId: widgetId(initialBoardRecord.get('id'), chartDefinition.id),
            joiSchema,
            appearanceService,
            objectNormalizer,
            objectFormatter,
          });
        });
      await Promise.all(promises);

      return {
        ...prevAppearanceSettingsShape,
        dashboard: {
          activeBoardIndex: 0,
          boards: [initialBoardRecord.toJS()],
        },
      };
    }

    return prevAppearanceSettingsShape;
  },
};
